<!-- 快递按年月分类页面 -->
<template>
  <div class="monthClassifyExpress">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>{{ title }}</span>
    </div>

    <div class="monthExpress">
      <div class="search">
        <div class="wrap">
          <input placeholder="输入您需要筛选的内容" />
          <van-icon name="search" size="18" />
          <div class="mask" @click="show = true"></div>
        </div>
      </div>

      <div class="express">
        <div
          class="expressItem"
          v-for="(item, index) in monthExpress.data"
          :key="index"
          @click="toExpressDetail(index)"
        >
          <div class="top">
            <div class="tag">
              <van-tag
                type="danger"
                v-if="
                  item.ExpressRegistration_ExpressUnusual &&
                  item.ExpressRegistration_ExpressUnusual != ''
                "
                >{{ item.ExpressRegistration_ExpressUnusual }}</van-tag
              >
              <van-tag
                type="success"
                v-else-if="item.ExpressRegistration_IsSignFor == 'true'"
                >已签收</van-tag
              >
              <van-tag
                type="warning"
                v-else-if="
                  item.ExpressRegistration_IsSignFor == 'false' &&
                  item.ExpressRegistration_OverTime -
                    item.ExpressRegistration_SendOffTime >=
                    0
                "
                >未签收</van-tag
              >
              <van-tag
                type="danger"
                v-else-if="
                  item.ExpressRegistration_IsSignFor == 'false' &&
                  item.ExpressRegistration_OverTime -
                    item.ExpressRegistration_SendOffTime <
                    0
                "
                >已超时</van-tag
              >
            </div>
            <div class="expressNumber">
              {{ item.ExpressRegistration_Num }}
            </div>
            <div class="cost">{{ item.ExpressRegistration_Cost }}元</div>
          </div>
          <div class="middle">{{ item.ExpressRegistration_ProjectName }}</div>
          <div class="bottom">
            <div class="goodsText">物品：</div>
            <div class="goods">{{ item.ExpressRegistration_MailService }}</div>
          </div>
        </div>

        <div class="bottomHint">已经到底了哦</div>
      </div>

      <div class="downloadButton">
        <button class="download" @click="downloadExcel">下载本月列表</button>
      </div>

      <van-popup v-model="show" :style="{ width: '100%', height: '100%' }">
        <div class="filter">
          <div class="filter-header">
            <van-icon name="cross" @click="show = false" />
            <span>筛选</span>
          </div>
          <div class="searchContent">
            <div class="title">
              筛选{{ year }}年{{ monthExpress.month }}月的快递单
            </div>
            <div class="searchExpressNum item">
              <div class="item-title">快递单号</div>
              <input
                class="expressnum item-value"
                placeholder="输入快递单号"
                v-model="expressNumber"
              />
            </div>
            <div class="searchProject item">
              <div class="item-title">项目</div>
              <div class="section">
                <input
                  type="text"
                  placeholder="选择项目"
                  v-model="projectName"
                  readonly
                  @click="isShowSelectProject = true"
                />
              </div>
            </div>
            <div class="searchIsSignFor item">
              <div class="item-title">是否签收</div>
              <van-radio-group v-model="isSignFor" checked-color="#3c43e3">
                <van-radio name="all">全部</van-radio>
                <van-radio name="signFor">已签收</van-radio>
                <van-radio name="unSignFor">未签收</van-radio>
              </van-radio-group>
            </div>

            <div class="button">
              <button class="cancelButton" @click="cancelSearch">取消</button>
              <button class="searchButton" @click="searchResult">搜索</button>
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="isShowSelectProject" position="bottom">
        <van-picker
          title="选择项目"
          show-toolbar
          :columns="projectList"
          @confirm="onConfirm"
          @cancel="isShowSelectProject = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "MonthClassifyExpress",
  data() {
    return {
      monthExpress: {},
      show: false,
      isShowSelectProject: false,
      year: "",
      result: [],
      projectList: [
        "不选择项目",
        "广州健新科技股份有限公司",
        "杭州巨星科技有限公司",
        "河北奎山水泥集团有限公司",
        "九号有限公司",
        "厦门合嘉源生活服务集团有限责任公司",
        "丽达集团",
        "南方电网",
        "翔龙集团",
        "宁德东侨国有资产投资建设有限公司",
        "浙江红蜻蜓鞋业股份有限公司",
        "河钢集团有限公司",
        "中电建生态环境集团有限公司",
        "中山公用水务有限公司",
        "中车株洲电力机车有限公司",
        "甘肃上峰水泥股份有限公司",
        "广州市卡宾服饰有限公司",
        "京蓝",
        "理昂生态能源",
        "厦门象屿",
        "陕西省地方电力（集团）",
        "成都成量工具集团有限公司",
        "杭州大搜车汽车服务有限公司",
        "北京新合作商业管理有限公司",
        "广物汽贸集团",
      ],
      expressNumber: "",
      projectName: "不选择项目",
      isSignFor: "all",
      fileUrl: "",
      showDownLoadHint: false,
      title: "",
    };
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },

    //路由跳转快递详情页面
    toExpressDetail(index) {
      sessionStorage.setItem(
        "currentExpressDetail",
        JSON.stringify(this.monthExpress.data[index])
      );
      this.$router.push("/expressDetail");
    },

    //取消搜索
    cancelSearch() {
      this.expressNumber = "";
      this.projectName = "不选择项目";
      this.isSignFor = "all";
      this.show = false;
    },

    // 选择项目
    onConfirm(value) {
      console.log(value);

      this.projectName = value;
      this.isShowSelectProject = false;
    },

    //搜索
    searchResult() {
      var arr = JSON.parse(JSON.stringify(this.monthExpress.data));
      if (this.expressNumber != "") {
        arr = arr.filter((e) => {
          return e.ExpressRegistration_Num.indexOf(this.expressNumber) != -1;
        });
      }
      if (this.projectName != "不选择项目") {
        arr = arr.filter((e) => {
          return e.ExpressRegistration_ProjectName == this.projectName;
        });
      }
      if (this.isSignFor == "signFor") {
        arr = arr.filter((e) => {
          return e.ExpressRegistration_IsSignFor == "true";
        });
      } else if (this.isSignFor == "unSignFor") {
        arr = arr.filter((e) => {
          return e.ExpressRegistration_IsSignFor == "false";
        });
      }

      if (arr.length == 0) {
        Toast("暂无符合条件的快递");
      } else {
        console.log(arr);

        this.$store.commit("setCcurrentSearchResult", arr);
        this.$router.push("/searchResult");
      }
    },

    //下载Excel表格
    downloadExcel() {
      var that = this;

      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      var info = {
        IsSystem: false,
        IsPagination: true, //分页
        data: [
          {
            "@ClassName": "peacepay", //类名
            "@MethodName": "ExportExcel",
          },
          {
            year: this.monthExpress.year,
            month: this.monthExpress.month,
            ExpressRegistration_EnterpriseID: JSON.parse(
              sessionStorage.getItem("userInfo")
            ).orgID,
          },
        ],
      };

      var fd = new FormData();

      fd.append("inputJson", JSON.stringify(info));
      fd.append("page", 1);
      fd.append("limit", 100000);

      Toast.loading({
        message: "正在下载...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      that.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/Home/GeneralExport",
          fd,
          config
        )
        .then(function (res) {
          console.log(res);
          if (res.data.errorCode == "1") {
            Toast.clear();
            var link = document.createElement("a");
            link.href = res.data.errorText;
            link.setAttribute("download", "");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            Toast(res.data.errorText);
          }
        })
        .catch(function (err) {
          Toast(err);
        });
    },

    //关闭文件下载提示框
    onClickHide() {
      this.showDownLoadHint = false;
      this.fileUrl = "";
    },

    //一键复制文件地址
    copyFileUrl() {
      wx.setClipboardData({
        data: this.fileUrl,
        success(res) {},
      });
    },
  },
  created() {
    if (this.$store.state.currentMonthExpress) {
      // if (app.globalData.projectList) {
      //   var project = JSON.parse(JSON.stringify(app.globalData.projectList));
      //   project.unshift("不选择项目");
      // }

      // var arr = JSON.parse(JSON.stringify(app.globalData.currentMonthExpress));

      // arr.arr.sort((a, b) => {
      //   return a.MailTime > b.MailTime ? -1 : 1
      // })

      var arr = JSON.parse(
        JSON.stringify(this.$store.state.currentMonthExpress)
      );

      // this.setData({
      //   monthExpress: arr,
      //   projectList: project,
      //   year: app.globalData.currentMonthExpress.year,
      // });

      this.monthExpress = arr;
      this.year = arr.year;

      this.title =
        this.monthExpress.year + "年" + this.monthExpress.month + "月";
    } else {
      Toast({
        message: "数据加载失败",
        forbidClick: true,
      });

      var that = this;

      setTimeout(() => {
        that.goback();
      }, 1500);
    }
  },
};
</script>

<style lang="less">
.monthClassifyExpress {
  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  .monthExpress {
    width: 100vw;
    min-height: calc(100vh - 44px);
  }

  .monthExpress .search {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .search .wrap {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    position: relative;
    background-color: #f2f2f2;
  }

  .search .wrap input {
    width: 100%;
    height: 30px;
    padding-left: 30px;
    border-radius: 15px;
    font-size: 14px;
    box-sizing: border-box;
    position: relative;
    color: #777777;
    background-color: #eff0f4;
  }

  .search .wrap .van-icon {
    font-size: 14px;
    position: absolute;
    top: 5px;
    left: 9px;
    z-index: 1;
    color: #bbbbbb;
  }

  .search .wrap .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .monthExpress .express {
    width: 100%;
    min-height: calc(100vh - 94px);
    // margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  .monthExpress .express .expressItem {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    margin-bottom: 5px;
    background-color: #ffffff;
    /* border-bottom: 1px solid #999999; */
  }

  .expressItem .top {
    width: 100%;
    display: flex;
    font-size: 14px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .expressItem .top .tag {
    margin-right: 15px;
  }

  .expressItem .top .expressNumber {
    flex: 1;
  }

  .expressItem .middle {
    font-size: 14px;
    margin-bottom: 5px;
    color: #999999;
  }

  .expressItem .bottom {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    color: #999999;
  }

  .expressItem .bottom .goods {
    flex: 1;
  }

  .monthExpress .bottomHint {
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #666666;
    padding-bottom: 50px;
  }

  .monthExpress .downloadButton {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 5px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .monthExpress .downloadButton .download {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #ffffff;
    background-color: #3c43e3;
    border-radius: 5px;
  }

  .filter {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 55px;
    box-sizing: border-box;
    overflow: hidden auto;
    background-color: #f5f5f5;
  }

  .filter-header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: relative;
    color: #ffffff;
    background-color: #3c43e3;
    position: fixed;
    top: 0;
    left: 0;
  }

  .filter-header .van-icon {
    position: absolute;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .filter .searchContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 1px 5px #cfcfcf;
    background-color: #ffffff;
    padding-top: 0;
    position: relative;
    padding: 10px 15px 20px 15px;
  }

  .searchContent .item {
    margin-bottom: 20px;

    .section input {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      color: #333333;
      font-size: 14px;
      border-radius: 5px;
      box-sizing: border-box;
      background-color: #eff0f4;
    }
  }

  .searchContent .title {
    width: 100%;
    padding: 0 10px 10px 10px;
    text-align: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
    border-bottom: 1px solid #e9eeef;
  }

  .searchContent .item-title {
    font-size: 14px;
    color: #999999;
    margin-bottom: 5px;
  }

  .searchContent .item-value {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    color: #333333;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eff0f4;
  }

  .searchContent .item-value.active {
    color: #cccccc;
  }

  .searchContent .van-cell {
    padding: 0;
    margin-bottom: 5px;
  }

  .van-radio {
    margin-bottom: 5px;
  }

  .searchContent .button {
    width: calc(100% - 20px);
    height: 45px;
    position: absolute;
    bottom: 15px;
    left: 10px;
    text-align: center;
  }

  .searchContent .button .searchButton {
    width: calc(50% - 10px);
    height: 40px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    border: 0;
    outline: 0;
    display: inline-block;
    line-height: 40px;
  }

  .searchContent .button .cancelButton {
    margin-right: 20px;
    width: calc(50% - 10px);
    height: 40px;
    text-align: center;
    font-size: 14px;
    outline: 0;
    display: inline-block;
    line-height: 40px;
  }

  /* .searchContent .button button.searchResult {
  margin-left: 20px
} */

  .downloadFileUrl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
  }

  .downloadFileUrl .hint-title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
    margin-bottom: 10px;
  }

  .downloadFileUrl textarea {
    width: 100%;
    height: 100px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #333333;
    box-sizing: border-box;
    padding: 10px;
  }

  .downloadFileUrl .button {
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: 10px;
  }

  .downloadFileUrl button {
    font-size: 14px;
    line-height: 30px;
    flex: 1;
  }

  .downloadFileUrl button.copy {
    margin-left: 20px;
    background-color: #3c43e3;
    color: #ffffff;
  }
}
</style>