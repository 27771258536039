<!-- 快递相信信息页面 -->
<template>
  <div class="expressDetail">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>快递信息</span>
    </div>
    <div class="express">
      <div class="wrap-Data">
        <div class="baseData">
          <div class="data-top">
            <div class="left">
              <div class="item expressNum">
                <div class="item-value">
                  快递单号：{{ express.ExpressRegistration_Num }}
                </div>
              </div>
              <div class="item projectName">
                <div class="item-value">
                  项目：{{ express.ExpressRegistration_ProjectName }}
                </div>
              </div>
              <div class="item contractNum">
                <div class="item-value">
                  合同编号：{{ express.ExpressRegistration_ContractNum }}
                </div>
              </div>
            </div>
            <van-image
              fit="cover"
              :src="stubUrl"
              @click="showBigImage(stubUrl)"
            />
          </div>

          <div class="data-middle">
            <div class="item senderMan">
              <div class="circle active">寄</div>
              <div class="item-title">寄件人</div>
              <div class="item-value">
                {{ express.ExpressRegistration_SenderName }}
              </div>
            </div>
            <div class="split">
              <img src="../assets/express_Image/wf.png" />
              <div class="splitLine"></div>
            </div>
            <div class="item consignee">
              <div class="circle active">收</div>
              <div class="item-title">收货人信息</div>
              <div class="item-value">
                {{ express.ExpressRegistration_ConsigneeInfor }}
              </div>
            </div>
          </div>
          <div class="data-bottom">
            <div class="item mailTime">
              <div class="item-title">寄件时间</div>
              <div class="item-value">
                {{ express.ExpressRegistration_MailTime }}
              </div>
            </div>
            <div class="item informMan">
              <div class="item-title">通知人</div>
              <div class="item-value">
                {{ express.ExpressRegistration_InformMan }}
              </div>
            </div>
            <div class="item signForTime">
              <div class="item-title">
                签收时间
                <van-tag
                  class="received"
                  type="success"
                  v-if="express.ExpressRegistration_IsSignFor == 'true'"
                  >已签收</van-tag
                >
                <van-tag class="received" type="warning" v-else>未签收</van-tag>
              </div>
              <div
                class="item-value"
                v-if="
                  express.ExpressRegistration_SignForTime &&
                  express.ExpressRegistration_IsSignFor
                "
              >
                {{ express.ExpressRegistration_SignForTime }}
              </div>
              <div class="item-value" v-else>未签收</div>
            </div>
            <div class="item expressCost">
              <div class="item-title">快递费用</div>
              <div class="item-value">
                {{ express.ExpressRegistration_Cost }}元
              </div>
            </div>
            <div class="item mailService">
              <div class="item-title">邮寄物品</div>
              <div class="item-value">
                {{ express.ExpressRegistration_MailService }}
              </div>
            </div>
          </div>
          <button class="editButton" @click="toEdit">
            <img src="../assets/express_Image/edit.png" />
            <span>编辑</span>
          </button>
        </div>
      </div>
      <div class="logistics">
        <div class="logisticsData">
          <LogisticsSteps
            :steps="express.ExpressRegistration_LogisticsInfor"
            :isSignFor="express.ExpressRegistration_IsSignFor"
            @update="updateLogistics($event)"
          ></LogisticsSteps>
        </div>
      </div>
    </div>

    <van-dialog id="van-dialog" confirm-button-color="#1989fa" />
  </div>
</template>

<script>
import { Toast, ImagePreview, Dialog } from "vant";
import LogisticsSteps from "../components/LogisticsSteps";
export default {
  name: "ExpressDetail",
  components: {
    LogisticsSteps,
  },
  data() {
    return {
      express: {},
      searchResult: [],
      stubUrl: "",
      active: 0,
      show: false,
      monthExpress: {},
      isSignFor: false,
    };
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
      sessionStorage.removeItem("currentExpressDetail")
    },

    //获取当前的快递详情
    getCurrentExpress(isHint) {
      var express = JSON.parse(sessionStorage.getItem("currentExpressDetail"));

      console.log(express);

      if (
        typeof express.ExpressRegistration_LogisticsInfor == "string" &&
        express.ExpressRegistration_LogisticsInfor
      ) {
        console.log("qqqq");
        express.ExpressRegistration_LogisticsInfor = JSON.parse(
          express.ExpressRegistration_LogisticsInfor
        );
      } else if (express.ExpressRegistration_LogisticsInfor === "") {
        express.ExpressRegistration_LogisticsInfor = [];
      }

      express.ExpressRegistration_MailTime = express.ExpressRegistration_MailTime.split(
        "T"
      )[0];
      if (express.ExpressRegistration_SignForTime) {
        express.ExpressRegistration_SignForTime = express.ExpressRegistration_SignForTime.replace(
          "T",
          " "
        );
        express.ExpressRegistration_SignForTime = express.ExpressRegistration_SignForTime.split(
          "."
        )[0];
      }

      this.express = express;
      this.stubUrl = express.ExpressRegistration_StubUrl;
      this.isSignFor = express.ExpressRegistration_IsSignFor;

      if (isHint) {
        Toast("信息更新成功");
      }
    },

    // 显示大图
    showBigImage(url) {
      ImagePreview({
        images: [url],
        startPosition: 0,
      });
    },

    //展示编辑输入框
    toEdit() {
      this.$router.push("/editExpress");
    },

    //更新物流信息
    updateLogistics(phone) {
      console.log(phone);
      var that = this;
      Dialog.confirm({
        title: "温馨提示",
        message: "请确认输入的手机尾号是否正确，如不正确将影响查询结果",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "正在更新物流信息...",
            loadingType: "spinner",
          });

          let fd = new FormData();

          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          fd.append("nu", this.express.ExpressRegistration_Num);

          this.$axios
            .post(
              "https://beyondsky.docuvix.com/peacepay/api/Home/GetActionInfo",
              fd,
              config
            )
            .then((res) => {
              console.log(res, JSON.parse(res.data).showapi_res_body.status);
              // if (res.data.errorCode == "1") {
              that.express.ExpressRegistration_LogisticsInfor = JSON.parse(
                res.data
              ).showapi_res_body.data;

              let formData = new FormData();

              var isSignFor = "false";

              var signForTime = "";

              console.log(
                "qqqqq0",
                JSON.parse(res.data).showapi_res_body.status
              );

              var json = [
                {
                  ExpressRegistration_LogisticsInfor: JSON.stringify(
                    this.express.ExpressRegistration_LogisticsInfor
                  ),
                },
              ];

              if (JSON.parse(res.data).showapi_res_body.status === 4) {
                isSignFor = "true";
                signForTime = JSON.parse(res.data).showapi_res_body.data[
                  JSON.parse(res.data).showapi_res_body.data.length - 1
                ].time;

                that.express.ExpressRegistration_IsSignFor = "true";
                that.express.ExpressRegistration_SignForTime = JSON.parse(
                  res.data
                ).showapi_res_body.data[
                  JSON.parse(res.data).showapi_res_body.data.length - 1
                ].time;

                json.push({
                  ExpressRegistration_IsSignFor: isSignFor,
                });

                json.push({
                  ExpressRegistration_SignForTime: signForTime,
                });
              }

              let info = {
                IsSystem: false,
                data: [
                  {
                    "@ClassName": "peacepay",
                    "@MethodName": "Update_ExpressRegistration",
                  },
                  {
                    json: JSON.stringify(json),
                    ExpressRegistration_ID: this.express.ExpressRegistration_ID,
                  },
                ],
              };

              formData.append("inputJson", JSON.stringify(info));

              this.$axios
                .post(
                  "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralOpreateData",
                  formData,
                  config
                )
                .then((result) => {
                  console.log(result);
                  if (result.data.errorCode == "1") {
                    Toast({
                      message: "修改成功",
                      forbidClick: true,
                    });

                    sessionStorage.removeItem("currentExpressDetail");

                    that.$store.commit("setKeepAlive", []);
                    
                    setTimeout(() => {
                      that.$router.replace("/");
                    }, 1500);
                  } else {
                    Toast(result.data.errorText);
                  }
                })
                .catch((error) => {
                  Toast(error);
                });
            })
            .catch((err) => {
              Toast(err);
            });
        })
        .catch(() => {
          
        });
    },
  },
  created() {
    this.getCurrentExpress();

    if (this.$store.state.currentMonthExpress.length > 0) {
      this.monthExpress = this.$store.state.currentMonthExpress;
    }

    if (this.$store.state.currentSearchResult.length > 0) {
      this.searchResult = this.$store.state.currentSearchResult;
    }
  },
};
</script>

<style lang="less">
.expressDetail {
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  /* div/expressDetail/expressDetail.wxss */
  .express {
    width: 100vw;
    min-height: calc(100vh - 44px);
    padding: 15px;
    box-sizing: border-box;
  }

  .express .wrap-Data {
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    /* padding-left: 42px; */
    box-sizing: border-box;
    box-shadow: 0 1px 5px #cfcfcf;
    background-color: #ffffff;
    margin-bottom: 15px;
  }

  .wrap-Data .data-top {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #f0eff5;
  }

  .data-top .left {
    flex: 1;
    text-align: left;
  }

  .left .item {
    margin-bottom: 5px;
  }

  .data-top .item .item-title {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .data-top .item .item-value {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .data-top .item .projectName .item-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .data-top img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
  }

  .data-middle {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0eff5;
  }

  .data-middle .item {
    width: 100%;
    /* height: 40rpx; */
    line-height: 20px;
    padding-left: 30px;
    /* margin-bottom: 10px; */
    display: flex;
    box-sizing: border-box;
    position: relative;
  }

  /* .data-middle .item.consignee {
  min-height: 40rpx;
} */

  .data-middle .item .item-title {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-right: 20px;
  }

  .data-middle .item .item-value {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    flex: 1;
    text-align: right;
  }

  .data-middle .split {
    width: 100%;
    height: 30px;
    line-height: 30px;
    display: flex;
  }

  .data-middle .split img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    transform: rotate(90deg);
    transform-origin: 10px 10px;
  }

  .data-middle .split .splitLine {
    flex: 1;
    height: 1px;
    margin-top: 14.5px;
    background-color: #f0eff5;
  }

  .data-middle .item .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    color: #ffffff;
    background-color: #2d6efe;
    text-align: center;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 4px;
  }

  .data-middle .item.senderMan .circle {
    background-color: #ff4d00;
  }

  .data-bottom {
    margin-top: 10px;
  }

  .data-bottom .item {
    width: 100%;
    line-height: 20px;
    margin-bottom: 5px;
    display: flex;
  }

  .data-bottom .item .item-title {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-right: 20px;
  }

  .data-bottom .item .item-value {
    flex: 1;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    text-align: right;
  }

  .express .editButton {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #3c43e3;
    text-align: center;
    margin-top: 20px;
  }

  .express .editButton img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    outline: 0;
    border: 0;
  }

  .express .editButton span {
    vertical-align: middle;
  }
}
</style>