<!-- 搜索结果页面 -->
<template>
  <div class="searchResult">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>搜索结果</span>
    </div>
    <div class="express">
      <template v-if="resultExpress.length > 0">
        <div
          class="expressItem"
          v-for="(item, index) in resultExpress"
          :key="index"
          @click="toExpressDetail(index)"
        >
          <div class="top">
            <div class="tag">
              <van-tag
                type="danger"
                v-if="
                  item.ExpressRegistration_ExpressUnusual &&
                  item.ExpressRegistration_ExpressUnusual != ''
                "
                >{{ item.ExpressRegistration_ExpressUnusual }}</van-tag
              >
              <van-tag
                type="success"
                v-else-if="item.ExpressRegistration_IsSignFor == 'true'"
                >已签收</van-tag
              >
              <van-tag
                type="warning"
                v-else-if="
                  item.ExpressRegistration_IsSignFor == 'false' &&
                  item.ExpressRegistration_OverTime -
                    item.ExpressRegistration_SendOffTime >=
                    0
                "
                >未签收</van-tag
              >
              <van-tag
                type="danger"
                v-else-if="
                  item.ExpressRegistration_IsSignFor == 'false' &&
                  item.ExpressRegistration_OverTime -
                    item.ExpressRegistration_SendOffTime <
                    0
                "
                >已超时</van-tag
              >
            </div>
            <div class="expressNumber">
              {{ item.ExpressRegistration_Num }}
            </div>
            <div class="cost">{{ item.ExpressRegistration_Cost }}元</div>
          </div>
          <div class="middle">{{ item.ExpressRegistration_ProjectName }}</div>
          <div class="bottom">
            <div class="goodsText">物品：</div>
            <div class="goods">{{ item.ExpressRegistration_MailService }}</div>
          </div>
        </div>
      </template>
      <Hint2 text="暂无符合条件的快递" v-else></Hint2>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Hint2 from "../components/Hint2";

export default {
  name: "SearchResult",
  data() {
    return {
      resultExpress: [],
    };
  },
  components: {
    Hint2,
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },

    // 跳转快递详情页面
    toExpressDetail(index) {
      sessionStorage.setItem(
        "currentExpressDetail",
        JSON.stringify(this.resultExpress[index])
      );

      this.$router.push("/expressDetail");
    },
  },
  created() {
    if (this.$store.state.currentSearchResult) {
      this.resultExpress = this.$store.state.currentSearchResult;

      for (let index = 0; index < this.resultExpress.length; index++) {
        var element = this.resultExpress[index];

        element.ExpressRegistration_SendOffTime = Number(
          element.ExpressRegistration_SendOffTime
        );
        element.ExpressRegistration_OverTime = Number(
          element.ExpressRegistration_OverTime
        );
      }
    } else {
      Toast({
        message: "数据加载失败",
        forbidClick: true,
      });

      setTimeout(() => {
        this.goback();
      }, 1500);
    }
  },
};
</script>

<style lang="less">
.searchResult {
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  .express {
    width: 100%;
    padding-top: 10px;
    margin-bottom: 20px;
  }

  .express .expressItem {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #ffffff;
    margin-bottom: 5px;
  }

  .expressItem .top {
    width: 100%;
    display: flex;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }

  .expressItem .top .tag {
    margin-right: 15px;
  }

  .expressItem .top .expressNumber {
    flex: 1;
  }

  .expressItem .middle {
    font-size: 14px;
    color: #999999;
    margin-bottom: 5px;
  }

  .expressItem .bottom {
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    display: flex;
  }

  .expressItem .bottom .goods {
    flex: 1;
  }
}
</style>