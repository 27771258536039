<!-- 首页 -->
<template>
  <div class="home">
    <van-tabbar
      v-model="active"
      active-color="#3c43e3"
      inactive-color="#515151"
    >
      <van-tabbar-item name="expressRegistration">
        <span>快递登记</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="expressStatistical">
        <span>快递统计</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="expressSearch">
        <span>快递查询</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="unsignedList">
        <span>未签清单</span>
        <template #icon="props">
          <img :src="props.active ? icon4.active : icon4.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <ExpressRegistration
      v-show="active === 'expressRegistration'"
    ></ExpressRegistration>

    <ExpressStatistical
      v-show="active === 'expressStatistical'"
    ></ExpressStatistical>

    <ExpressSearch v-show="active === 'expressSearch'"></ExpressSearch>

    <UnsignedList v-show="active === 'unsignedList'"></UnsignedList>
  </div>
</template>

<script>
import ExpressRegistration from "../components/ExpressRegistration";
import ExpressStatistical from "../components/ExpressStatistical";
import ExpressSearch from "../components/ExpressSearch";
import UnsignedList from "../components/UnsignedList";

export default {
  name: "Home",
  data() {
    return {
      active: "expressRegistration",
      icon1: {
        active: require("../assets/icon/kddj_active.png"),
        inactive: require("../assets/icon/kddj.png"),
      },
      icon2: {
        active: require("../assets/icon/kdtj_active.png"),
        inactive: require("../assets/icon/kdtj.png"),
      },
      icon3: {
        active: require("../assets/icon/kdcx_active.png"),
        inactive: require("../assets/icon/kdcx.png"),
      },
      icon4: {
        active: require("../assets/icon/wqqd_active.png"),
        inactive: require("../assets/icon/wqqd.png"),
      },
    };
  },
  components: {
    ExpressRegistration,
    ExpressStatistical,
    ExpressSearch,
    UnsignedList,
  },
  methods: {},
  created() {
    this.$store.commit("setKeepAlive", ["Home"]);
  },
};
</script>

<style lang="less">
/* fade(过渡) */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
