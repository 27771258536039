<!-- 未签收快递清单页面 -->
<template>
  <div class="unsignedList">
    <div class="header">
      <span>未签清单</span>
    </div>
    <van-pull-refresh v-model="refreshLoading" @refresh="getUnSignForExpress">
      <div class="list">
        <div class="wrap" v-if="!isloading">
          <div class="express" v-if="unFignForExpress.length > 0">
            <div
              class="expressItem"
              v-for="(item, index) in unFignForExpress"
              :key="index"
              @click="toExpressDetail(index)"
            >
              <div class="left">
                <div class="expressNum">{{ item.ExpressRegistration_Num }}</div>
                <div class="projectName">
                  {{ item.ExpressRegistration_ProjectName }}
                </div>
                <div class="goods">
                  <div class="text">物品：</div>
                  <div class="value">
                    {{ item.ExpressRegistration_MailService }}
                  </div>
                </div>
              </div>
              <div class="right">
                <van-tag
                  type="danger"
                  v-if="item.ExpressRegistration_ExpressUnusual"
                  >{{ item.ExpressRegistration_ExpressUnusual }}</van-tag
                >
                <van-tag
                  type="danger"
                  v-else-if="
                    item.ExpressRegistration_OverTime -
                      item.ExpressRegistration_SendOffTime <
                    0
                  "
                  >已超时</van-tag
                >
                <van-tag type="warning" v-else>未签收</van-tag>
              </div>
            </div>
            <div class="bottomHint">已经到底了哦</div>
          </div>
          <Hint2 text="暂无未签收快递" v-else></Hint2>
        </div>
        <div class="loadingWrap" v-else>
          <van-loading type="spinner" color="#666666" />
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Hint2 from "../components/Hint2";
import { Toast } from "vant";

export default {
  name: "UnsignedList",
  data() {
    return {
      userInfo: {},
      active: 2,
      unFignForExpress: [],
      isloading: false,
      refreshLoading: false,
    };
  },
  components: { Hint2 },
  methods: {
    // 获取未签收的快递
    getUnSignForExpress() {
      this.isloading = true;

      let fd = new FormData();

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var sendForTimeStart = "1900-01-01";

      var sendForTimeEnd = "2099-12-31";

      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "GetExpressRegistrationInfo",
          },
          {
            ExpressRegistration_Num: "",
            ExpressRegistration_ProjectName: "",
            ExpressRegistration_ConsigneeInfor: "",
            ExpressRegistration_MailService: "",
            ExpressRegistration_IsSignFor: "false",
            begintime: sendForTimeStart,
            endtime: sendForTimeEnd,
          },
        ],
      };

      fd.append("inputJson", JSON.stringify(info));
      fd.append("limit", 1000);
      fd.append("page", 1);

      this.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          if (res.data.errorCode == "1") {
            this.unFignForExpress = res.data.data;
            this.isloading = false;
          } else {
            Toast(res.data.errorText);
          }
          this.refreshLoading = false;
        })
        .catch((err) => {
          Toast(err);
          this.refreshLoading = false;
        });
    },

    // 跳转快递详情
    toExpressDetail(index) {
      sessionStorage.setItem(
        "currentExpressDetail",
        JSON.stringify(this.unFignForExpress[index])
      );

      this.$router.push("/expressDetail");
    },
  },
  created() {
    this.getUnSignForExpress();
  },
};
</script>

<style lang="less">
.unsignedList {
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
  }

  .list {
    width: 100vw;
  }

  .list .wrap {
    width: 100%;
    min-height: calc(100vh - 44px);
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  .list .express {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .list .express .expressItem {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #ffffff;
    display: flex;
    font-size: 32rpx;
    color: #333;
    margin-bottom: 5px;
  }

  .expressItem .left {
    flex: 1;
  }

  .expressItem .left .expressNum {
    margin-bottom: 5px;
  }

  .expressItem .left .projectName {
    // font-size: 13px;
    color: #999999;
  }

  .expressItem .left .goods {
    color: #999999;
    // font-size: 13px;
    margin-top: 5px;
    display: flex;
    line-height: 20px;
  }

  .expressItem .left .goods .value {
    flex: 1;
  }

  .expressItem .right {
    margin-left: 10px;
  }

  .list .bottomHint {
    text-align: center;
    line-height: 30px;
    font-size: 30rpx;
    color: #666666;
    padding-top: 10px;
  }

  /* loading样式 */
  .loadingWrap {
    padding-top: 20px;
    text-align: center;
  }
}
</style>