<!-- 用户信息页面 -->
<template>
  <div class="userInfo">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>用户信息</span>
    </div>

    <div class="info">
      <h4 class="title">基础资料</h4>
      <div class="infoItem">
        <div class="itemTitle">姓名</div>
        <div class="itemValue">{{ userInfo.userName }}</div>
      </div>
      <div class="infoItem">
        <div class="itemTitle">角色</div>
        <div class="itemValue">{{ userInfo.role }}</div>
      </div>
      <div class="infoItem">
        <div class="itemTitle">电话</div>
        <div class="itemValue">{{ userInfo.phone }}</div>
      </div>
      <div class="infoItem">
        <div class="itemTitle">邮箱</div>
        <div class="itemValue">{{ userInfo.email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
    }
  },
};
</script>

<style lang="less">
.userInfo {
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  .info {
    width: 100vw;
  }

  .title {
    line-height: 30px;
    font-size: 13px;
    margin-left: 10px;
    color: #333333;
  }

  .info .infoItem {
    width: 100%;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #ffffff;
    // border-bottom: 1px solid #f5f5f5;
    display: flex;
  }

  .info .infoItem .itemTitle {
    color: #000000;
    margin-bottom: 10px;
  }

  .info .infoItem .itemValue {
    width: 100%;
    color: #999999;
    flex: 1;
    text-align: right;
    line-height: 40px;
  }
}
</style>