<!-- 提示 类型2 -->
<template>
  <div class="hintOtherType">
    <img class="null" src="../assets/express_Image/null.png" />
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Hint2",
  props: {
    text: String,
  },
};
</script>

<style lang="less">
.hintOtherType {
  width: 100%;
  display: block;
  text-align: center;
  color: #cccccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hintOtherType .null {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
}

.hintOtherType .text {
  font-size: 28rpx;
  vertical-align: middle;
}
</style>