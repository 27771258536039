<!-- 快递搜索页面 -->
<template>
  <div class="expressSearch">
    <div class="header">
      <div class="navigation">
        <div class="icon" @click="show = true">我</div>
        <span class="title">快递查询</span>
        <!-- <div class="button" @click="toRegister">快递登记</div> -->
      </div>
    </div>
    <div class="searchWrap">
      <div class="searchContent">
        <div class="wrap">
          <div class="item expressNum">
            <h4 class="item-title">快递单号</h4>
            <div class="item-value">
              <input
                class="valueInput"
                v-model="expressNum"
                placeholder="请输入快递单号"
                placeholder-style="color:#cccccc"
              />

              <img
                src="../assets/express_Image/saoyisao.png"
                @click="scancCode"
              />
            </div>
          </div>
        </div>

        <div class="wrap">
          <div class="item projectName">
            <h4 class="item-title">项目</h4>
            <div class="item-value">
              <input
                class="valueInput"
                @input="bindProjectNameChange"
                v-model="projectName"
                placeholder="请输入项目名称"
              />

              <div
                class="hintOptions"
                v-if="showHint && hintOptions.length > 0"
              >
                <div
                  class="option"
                  v-for="(item, index) in hintOptions"
                  :key="index"
                  @click.stop="comfirmProjectName(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap">
          <div class="item consignee">
            <h4 class="item-title">签收人信息</h4>
            <textarea class="item-value" v-model="consignee"></textarea>
          </div>
        </div>

        <div class="wrap">
          <div class="item goods">
            <h4 class="item-title">物品信息</h4>
            <textarea class="item-value" v-model="goods"></textarea>
          </div>
        </div>

        <div class="wrap">
          <div class="item isSignFor">
            <h4 class="item-title">是否签收</h4>
            <van-radio-group v-model="isSignFor" checked-color="#3c43e3">
              <van-radio name="all">全部</van-radio>
              <van-radio name="signFor">已签收</van-radio>
              <van-radio name="unSignFor">未签收</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="item sendForTime">
          <h4 class="item-title">寄出时间（选择范围）</h4>
          <div class="timeScope">
            <div class="section">
              <div class="inputWrap">
                <img src="../assets/express_Image/calendar.png" />
                <input
                  type="text"
                  v-model="sendForTimeStart"
                  readonly
                  @click="isSowSendTimeStartSelect = true"
                />
              </div>
            </div>

            <div class="section">
              <div class="inputWrap">
                <img src="../assets/express_Image/calendar.png" />
                <input
                  type="text"
                  v-model="sendForTimeEnd"
                  readonly
                  @click="isSowSendTimeEndSelect = true"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="button">
          <button class="search" @click="search">搜索</button>
          <button class="clear" @click="clear">清空</button>
        </div>
      </div>
    </div>
    <van-dialog id="van-dialog" confirm-button-color="#1989fa" />

    <van-popup
      v-model="show"
      position="left"
      :style="{
        width: '70%',
        height: '100%',
      }"
    >
      <div class="data-header">
        <div class="left avatar">{{ userInfo.userName.split("")[0] }}</div>
        <div class="right infor">
          <div class="name">{{ userInfo.userName }}</div>
          <div class="role">{{ userInfo.role }}</div>
        </div>
      </div>
      <div class="function">
        <div class="item" @click="toInfor">
          <img src="../assets/express_Image/userInfoIcon.png" /><span
            >我的信息</span
          >
        </div>
        <!-- <div class="item" @click="toSetting">
          <img src="../assets/express_Image/settingIcon.png" /><span
            >设置
          </span>
        </div> -->
        <div class="item" @click="exit">
          <img src="../assets/express_Image/exitIcon.png" /><span
            >退出登录</span
          >
        </div>
      </div>
    </van-popup>

    <van-popup v-model="isSowSendTimeStartSelect" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择寄件起始时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="sendTimeStartSelect"
        @cancel="isSowSendTimeStartSelect = false"
      />
    </van-popup>

    <van-popup v-model="isSowSendTimeEndSelect" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择寄件结束时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="sendTimeEndSelect"
        @cancel="isSowSendTimeEndSelect = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import wx from "weixin-js-sdk";
export default {
  name: "ExpressSearch",
  data() {
    return {
      userInfo: {},
      active: 1,
      express: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 11, 31),
      currentDate: new Date(),
      projectList: [
        "广州健新科技股份有限公司",
        "杭州巨星科技有限公司",
        "河北奎山水泥集团有限公司",
        "九号有限公司",
        "厦门合嘉源生活服务集团有限责任公司",
        "丽达集团",
        "南方电网",
        "翔龙集团",
        "宁德东侨国有资产投资建设有限公司",
        "浙江红蜻蜓鞋业股份有限公司",
        "河钢集团有限公司",
        "中电建生态环境集团有限公司",
        "中山公用水务有限公司",
        "中车株洲电力机车有限公司",
        "甘肃上峰水泥股份有限公司",
        "广州市卡宾服饰有限公司",
        "京蓝",
        "理昂生态能源",
        "厦门象屿",
        "陕西省地方电力（集团）",
        "成都成量工具集团有限公司",
        "杭州大搜车汽车服务有限公司",
        "北京新合作商业管理有限公司",
        "广物汽贸集团",
      ],
      show: false,
      expressNum: "",
      projectName: "",
      isSignFor: "all",
      consignee: "",
      goods: "",
      sendForTimeStart: "",
      sendForTimeEnd: "",
      hintOptions: [],
      showHint: false,
      isOis: false,
      isSowSendTimeStartSelect: false,
      isSowSendTimeEndSelect: false,
    };
  },
  methods: {
    //获取所有项目列表
    getProjectList() {
      this.$axios
        .post("https://emonitor.docuvix.com:8088/GetProjectName")
        .then((result) => {
          var ProjectList = result.data.data;
          ProjectList.unshift("无项目");

          this.projectList = ProjectList;
        })
        .catch((err) => {});
    },

    // 退出登录
    exit() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定需要退出登录吗？",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          this.show = false;
        })
        .catch(() => {
          // on cancel
        });
    },

    // 扫一扫
    scancCode() {
      var that = this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res);
          that.expressNum = res.resultStr.split(",")[1];
        },
      });
    },

    //跳转到个人信息页面
    toInfor() {
      this.$router.push("/userInfo");
      this.show = false;
    },

    //跳转到超时提醒设置页面
    toSetting() {
      this.$router.push("/setting");
    },

    // 选择起始寄件时间
    sendTimeStartSelect(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth();
      var d = time.getDate();

      if (m < 10) {
        m = "0" + m;
      }

      if (d < 10) {
        d = "0" + d;
      }
      console.log(y, m, d);

      this.sendForTimeStart = y + "-" + m + "-" + d;
      this.isSowSendTimeStartSelect = false;
    },

    // 选择结束寄件时间
    sendTimeEndSelect(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth();
      var d = time.getDate();

      if (m < 10) {
        m = "0" + m;
      }

      if (d < 10) {
        d = "0" + d;
      }
      console.log(y, m, d);

      this.sendForTimeEnd = y + "-" + m + "-" + d;
      this.isSowSendTimeEndSelect = false;
    },

    // 输入项目名称时展开项目名称选择
    bindProjectNameChange() {
      var projectNames = [];
      if (
        this.projectName.trim() &&
        this.projectName.trim() != "" &&
        !this.isOis
      ) {
        var projectNames = this.projectList.filter((item) => {
          return item.indexOf(this.projectName) > -1;
        });
        // this.setData({
        //   projectName: e.detail.value,
        //   hintOptions: projectNames,
        //   showHint: true,
        // });

        this.hintOptions = projectNames;
        this.showHint = true;
      } else {
        // this.setData({
        //   projectName: e.detail.value,
        //   showHint: false,
        // });
        this.showHint = false;
      }
    },

    // 项目名称选择
    comfirmProjectName(item) {
      this.projectName = item;
      this.hintOptions = [];
    },

    //实时更新选择是否签收
    onChange(event) {
      this.isSignFor = event.detail;
    },

    //实时更新寄出时间开始阶段
    // bindDateStartChange(event) {
    //   this.setData({
    //     sendForTimeStart: event.detail.value,
    //   });
    // },

    // //实时更新寄出时间结束阶段
    // bindDateEndChange(event) {
    //   this.setData({
    //     sendForTimeEnd: event.detail.value,
    //   });
    // },

    //单选框点击事件
    onClick(event) {
      this.setData({
        isSignFor: event.currentTarget.dataset.name,
      });
    },

    // 搜索
    search() {
      if (
        this.sendForTimeEnd &&
        this.sendForTimeStart &&
        this.sendForTimeEnd < this.sendForTimeStart
      ) {
        Toast("寄件时间范围错误");
      } else {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "正在搜索...",
          loadingType: "spinner",
        });
        let fd = new FormData();

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        var isSignFor = "";

        if (this.isSignFor === "signFor") {
          isSignFor = "true";
        } else if (this.isSignFor === "unSignFor") {
          isSignFor = "false";
        }

        var sendForTimeStart = "1900-01-01";

        if (this.sendForTimeStart) {
          sendForTimeStart = this.sendForTimeStart;
        }

        var sendForTimeEnd = "2099-12-31";

        if (this.sendForTimeStart) {
          sendForTimeEnd = this.sendForTimeEnd;
        }

        let info = {
          IsSystem: false,
          IsPagination: true,
          data: [
            {
              "@ClassName": "peacepay",
              "@MethodName": "GetExpressRegistrationInfo",
            },
            {
              ExpressRegistration_Num: this.expressNum,
              ExpressRegistration_ProjectName: this.projectName,
              ExpressRegistration_ConsigneeInfor: this.consignee,
              ExpressRegistration_MailService: this.goods,
              ExpressRegistration_IsSignFor: isSignFor,
              begintime: sendForTimeStart,
              endtime: sendForTimeEnd,
            },
          ],
        };

        fd.append("inputJson", JSON.stringify(info));
        fd.append("limit", 1000);
        fd.append("page", 1);

        this.$axios
          .post(
            "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
            fd,
            config
          )
          .then((res) => {
            console.log(res);
            if (res.data.errorCode == "1") {
              Toast.clear();
              this.$store.commit("setCcurrentSearchResult", res.data.data);
              this.$router.push("/searchResult");
            } else {
              Toast(res.data.errorText);
            }
          })
          .catch((err) => {
            Toast(err);
          });
      }
    },

    // 清空
    clear() {
      this.expressNum = "";
      this.projectName = "";
      this.isSignFor = "all";
      this.consignee = "";
      this.good = "";
      this.sendForTimeStart = "";
      this.sendForTimeEnd = "";
    },
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
};
</script>

<style lang="less">
.expressSearch {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 50px;
  background-color: #f5f5f5;

  /* 头部 */
  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    text-align: center;
    box-sizing: border-box;
    /* border-bottom: 1px solid #999; */
    background-color: #3c43e3;
    color: #fff;
    font-size: 14px;
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 2;
  }

  .navigation {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
  }

  .header .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    /* border: 1px solid #999; */
    line-height: 24px;
    background-color: #fff;
    color: #3c43e3;
    box-sizing: border-box;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  /* .header .button {
  line-height: 40px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 10px;
} */

  /* 内容 */
  .searchContent .van-cell {
    padding: 0;
    margin-bottom: 5px;
  }

  .searchContent .van-cell__title {
    font-size: 14px;
    color: #777777;
  }

  .searchWrap {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .searchContent {
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #eeeeee;
  }

  .searchContent .wrap {
    width: 100%;
    display: flex;
  }

  .searchContent .wrap .item {
    flex: 1;
  }

  .searchContent .item {
    margin-bottom: 20px;
  }

  .item .item-title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }

  .item .item-value {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    color: #333333;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eff0f4;
  }

  .item.expressNum .item-value {
    width: 100%;
    height: 30px;
    display: flex;
    padding: 0;
    background-color: transparent;

    img {
      width: 26px;
      height: 26px;
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  .item.projectName .item-value {
    position: relative;
  }

  .item.projectName .item-value input {
    width: 100%;
    height: 30px;
    background-color: transparent;
  }

  .item.projectName .item-value .hintOptions {
    position: absolute;
    top: 31px;
    right: 0;
    width: 100%;
    max-height: 320px;
    overflow-y: auto;
    border: 1px solid #bebebe;
    background-color: #ffffff;
    z-index: 999;
  }

  .item.projectName .item-value .hintOptions .option {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    font-size: 14px;
    text-align: left;
    box-sizing: border-box;
    background-color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .item.expressNum .item-value .valueInput {
    flex: 1;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    color: #333333;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eff0f4;
  }

  .item .item-value.active {
    color: #cccccc;
  }

  .item textarea.item-value {
    padding: 8px 10px;
    height: 60px;
    line-height: 20px;
  }

  .van-radio {
    margin-bottom: 5px;
  }

  .timeScope {
    width: 100%;
    text-align: center;
    display: flex;

    .section {
      flex: 1;

      &:first-child {
        margin-right: 20px;
      }

      .inputWrap {
        width: 100%;
        height: 30px;
        position: relative;

        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
          padding-right: 30px;
          box-sizing: border-box;
          background-color: #eff0f4;
        }

        img {
          position: absolute;
          top: 50%;
          right: 7.5px;
          width: 15px;
          height: 15px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .timeScope .line {
    margin: 0 5px;
  }

  .searchContent .item.sendForTime .timeScope {
    display: flex;
  }

  .timeScope .section {
    flex: 1;
  }

  .timeScope .section:first-child {
    margin-right: 20px;
  }

  .timeScope .section .time-value {
    width: 100%;
    height: 30px;
    text-align: left;
    line-height: 30px;
    padding: 0 10px;
    color: #333333;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eff0f4;
    position: relative;
  }

  .timeScope .section .time-value image {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 7.5px;
    right: 10px;
  }

  .searchContent .button {
    width: 100%;
    margin-top: 20px;
    /* display: flex; */
  }

  .searchContent .button button {
    /* flex: 1; */
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
  }

  .searchContent .button .search {
    background-color: #3c43e3;
    color: #fff;
    margin-right: 10px;
    border: 1px solid #3c43e3;
    outline: none;
  }

  .searchContent .button .clear {
    margin-top: 10px;
    color: #333;
    background-color: #ffffff;
    border: 1px solid #dddddd;
  }

  /* 左边弹窗 */
  .van-popup {
    box-sizing: border-box;
    padding: 20px;
  }

  .van-popup .data-header {
    padding: 10px 0;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #eeeeee;
  }

  .van-popup .data-header .left {
    display: inline-block;
    width: 80px;
    height: 80px;
    vertical-align: middle;
    margin-right: 15px;
    border-radius: 50%;
    background-color: #3c43e3;
    color: #fff;
    text-align: center;
    line-height: 80px;
    font-size: 24px;
    font-weight: bold;
  }

  .van-popup .data-header .right {
    display: inline-block;
    vertical-align: middle;
  }

  .van-popup .data-header .right .name {
    line-height: 35px;
    font-size: 20px;
  }

  .van-popup .data-header .right .role {
    line-height: 20px;
    font-size: 14px;
  }

  .van-popup .function {
    margin-top: 10px;
    width: 100%;
  }

  .van-popup .function .item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #333;
    border-bottom: 1px solid #e3e3e3;
  }

  .van-popup .function .item img {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: middle;
  }

  .van-popup .function .item span {
    vertical-align: middle;
    font-size: 14px;
  }
}
</style>