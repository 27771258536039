<template>
  <div id="app">
    <transition name="slide-left">
      <keep-alive :include="keepAlive">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { Dialog } from "vant";
import wx from "weixin-js-sdk";

export default {
  name: "app",
  components: {},
  data() {
    return {
      express: [],
      currentMonthExpress: [],
      currentExpressDetail: {},
      unFignForExpress: [],
      projectList: [],
      docuvixList: [],
      searchResult: [],
      overTime: 5,
    };
  },
  computed: {
    // 设置keep-alive缓存的页面
    keepAlive() {
      return this.$store.getters.keepAlive;
    },
  },
  methods: {
    configurationWeiXinCode() {
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      let fd = new FormData();

      fd.append("url", window.location.href.split("#")[0]);

      console.log(window.location.href.split("#")[0]);

      this.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/Home/GetSignature",
          fd,
          config
        )
        .then((res) => {
          console.log(res);

          console.log(
            "timestamp:" + res.data.timestamp,
            "nonceStr:" + res.data.noncestr,
            "signature:" + res.data.signature
          );

          wx.config({
            debug: false,
            appId: "wx27e60c547a5ab724",
            timestamp: res.data.timestamp,
            nonceStr: res.data.noncestr,
            signature: res.data.signature,
            jsApiList: [
              "chooseImage",
              "uploadImage",
              "getLocalImgData",
              "previewImage",
              "getNetworkType",
              "checkJsApi",
              "scanQRCode",
            ],
          });
          // checkJsApi判断当前客户端版本是否支持指定JS接口，可以不需要调。
          wx.ready(function () {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          });
          wx.error(function (res) {
            console.log(res);
            Dialog.alert({
              title: "温馨提示",
              message: JSON.stringify(res),
              confirmButtonColor: "#3c43e3",
            }).then(() => {});
          });

          wx.checkJsApi({
            jsApiList: [
              "chooseImage",
              "uploadImage",
              "getLocalImgData",
              "previewImage",
              "getNetworkType",
              "checkJsApi",
              "scanQRCode",
            ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              console.log(res);
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            },
          });
        });
    },
  },
  created() {
    if (!sessionStorage.getItem("userInfo")) {
      sessionStorage.setItem(
        "userInfo",
        JSON.stringify({
          EnterpriseName: "道可维斯信息技术有限公司",
          account: "0004@经办人",
          email: "wenqiang.li@docuvix.com",
          id: "50b1bd2f-65f9-11eb-9a5c-00163e1697a5",
          orgID: "50b1bd0b-65f9-11eb-9a5c-00163e1697a5",
          password: "e10adc3949ba59abbe56e057f20f883e",
          phone: "18218525166",
          role: "经办人",
          roleID: "8b5352fa-3a82-4aa3-a2dd-6a505b306068",
          userName: "刘文强",
        })
      );
    }

    this.configurationWeiXinCode();
  },
};
</script>

<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 14px;
}

#app::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0 auto;
}

button {
  outline: none;
  border: none;
}

input,
textarea {
  border: none;
  outline: none;

  &:-ms-input-placeholder {
    color: #999999;
    font-size: 14px;
  }

  &::-webkit-input-placeholder {
    color: #999999;
    font-size: 14px;
  }

  &:-moz-placeholder {
    color: #999999;
    font-size: 14px;
  }

  &::-moz-placeholder {
    color: #999999;
    font-size: 14px;
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.5s;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
