import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import Home from '../views/Home.vue'
// 按月分类快递展示页面
import MonthClassifyExpress from '../views/MonthClassifyExpress.vue'
// 搜索结果页面
import SearchResult from '../views/SearchResult.vue'
// 快递详情页面
import ExpressDetail from '../views/ExpressDetail.vue'
// 编辑快递信息页面
import EditExpress from '../views/EditExpress.vue'
// 用户信息页面
import UserInfo from '../views/UserInfo.vue'
// 设置页面
import Setting from '../views/Setting.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/monthClassifyExpress',
    name: 'MonthClassifyExpress',
    component: MonthClassifyExpress
}, {
    path: '/searchResult',
    name: 'SearchResult',
    component: SearchResult
}, {
    path: '/expressDetail',
    name: 'ExpressDetail',
    component: ExpressDetail
}, {
    path: '/editExpress',
    name: 'EditExpress',
    component: EditExpress
}, {
    path: '/userInfo',
    name: 'UserInfo',
    component: UserInfo
}, {
    path: '/setting',
    name: 'Setting',
    component: Setting
}, ]

const router = new VueRouter({
    routes
})

export default router