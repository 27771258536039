<!-- 物流信息组件 -->
<template>
  <div class="logisticsSteps">
    <div class="steps">
      <div class="updateLogistics">
        <input
          class="tail"
          placeholder="请输入快递寄件人或者收货人的尾号"
          v-model="tail"
        /><button class="updateButton" @click="updateLogistics">更新</button>
      </div>

      <div class="progress" v-if="steps.length > 0">
        <div :class="isLookMore && steps.length > 2 ? 'wrap' : 'wrap more'">
          <div :class="isLookMore ? 'line' : 'line active'"></div>
          <div class="content">
            <div
              v-for="(item, index) in steps"
              :key="index"
              :class="
                index == 0
                  ? 'active item'
                  : 'item' && index == steps.length - 1
                  ? 'last item'
                  : 'item'
              "
            >
              <div class="context">{{ item.context }}</div>
              <div class="time">{{ item.time }}</div>
              <div class="circle" v-if="isSignFor =='true' && index == 0">收</div>
              <div class="circle" v-else-if="isSignFor =='false' && index == 0">运</div>
              <div class="circle" v-else></div>
            </div>
          </div>
        </div>
        <div
          class="moreHint"
          v-if="isLookMore && steps.length > 2"
          @click="lookMore"
        >
          <span class="text">点击查看更多物流详情</span>
          <img class="icon" src="../assets/express_Image/icon-down.png" />
        </div>
      </div>

      <Hint text="暂无物流信息" v-else></Hint>
    </div>
  </div>
</template>

<script>
import Hint from "../components/Hint";
import { Toast } from "vant";
export default {
  name: "LogisticsSteps",
  components: {
    Hint,
  },
  props: {
    steps: Array,
    isSignFor: String,
  },
  data() {
    return {
      isLookMore: true,
      tail: "",
    };
  },
  methods: {
    //点击查看更多物流详情
    lookMore() {
      this.height = this.standardHeight;
      this.isLookMore = false;
    },

    //更新
    updateLogistics() {
      if (this.tail && this.tail != "") {
        this.$emit("update", this.tail);
      } else {
        Toast("请输入快递寄件人或者收货人的尾号");
      }
    },
  },
};
</script>

<style lang="less">
.logisticsSteps {
  .steps {
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px #cfcfcf;
    background-color: #ffffff;
    overflow: hidden;
    padding-bottom: 40px;
    position: relative;
  }

  .updateLogistics {
    width: 100%;
    height: 20px;
    line-height: 20px;
    padding-left: 21px;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    // position: absolute;
    // top: 10px;
    // left: 0;

    input {
      &:-ms-input-placeholder {
        font-size: 13px;
      }

      &::-webkit-input-placeholder {
        font-size: 13px;
      }

      &:-moz-placeholder {
        font-size: 13px;
      }

      &::-moz-placeholder {
        font-size: 13px;
      }
    }
  }

  .updateLogistics .tail {
    flex: 1;
    font-size: 13px;
    color: #333333;
    line-height: 20px;
    border-bottom: 1px solid #cfcfcf;
    text-align: left;
    vertical-align: middle;
  }

  .updateButton {
    display: inline-block;
    width: 60px;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
    margin-left: 10px;
    font-size: 13px;
    color: #ffffff;
    background-color: #3c43e3;
    text-align: center;
    vertical-align: middle;
  }

  .moreHint {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    text-align: center;
    color: #999999;
    position: absolute;
    bottom: 10px;
    left: 0;
  }

  .moreHint .text {
    vertical-align: middle;
  }

  .moreHint .icon {
    width: 13px;
    height: 13px;
    margin-left: 3px;
    vertical-align: middle;
  }

  .steps .line.active::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #dddddd;
    position: absolute;
    bottom: 0;
    left: -4px;
  }

  .wrap {
    max-height: 150px;
    overflow-y: hidden;
    display: flex;
  }

  .wrap.more {
    max-height: 100%;
  }

  .steps .line {
    width: 2px;
    margin-right: 15px;
    margin-left: 10px;
    background-color: #dddddd;
    position: relative;
  }

  .steps .content {
    flex: 1;
  }

  .steps .content .item {
    margin-bottom: 15px;
    font-size: 13px;
    color: #999999;
    position: relative;
  }

  .steps .content .item.active {
    color: #333333;
  }

  .steps .content .item .context {
    font-size: 13px;
    margin-bottom: 5px;
    /* position: relative; */
  }

  .steps .content .item .time {
    font-size: 13px;
  }

  .steps .content .item .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cccccc;
    position: absolute;
    top: 0;
    left: -21px;
  }

  .steps .content .item.active .circle {
    width: 20px;
    height: 20px;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    color: #ffffff;
    background-color: #ff4d00;
    /* top: -10px; */
    left: -26px;
  }
}
</style>