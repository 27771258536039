<!-- 编辑快递信息页面 -->
<template>
  <div class="editExpress">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>快递信息</span>
    </div>

    <div class="wrap">
      <div class="item expressNum">
        <div class="item-title">快递单号</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_Num"
          readonly
          placeholder="请输入快递单号"
        />
      </div>
      <div class="item projectName">
        <div class="item-title">项目</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_ProjectName"
          placeholder="请输入项目名称"
        />
      </div>
      <div class="item contractNum">
        <div class="item-title">合同编号</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_ContractNum"
          placeholder="请输入合同编号"
        />
      </div>
    </div>

    <div class="wrap">
      <div class="item mailTime">
        <div class="item-title">寄件时间</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_MailTime"
          placeholder="请选择寄件时间"
          readonly
          @click="isShowMailTimeSelect = true"
        />
      </div>

      <div class="item contractNum">
        <div class="item-title">寄件人</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_SenderName"
          placeholder="请输入寄件人姓名"
        />
      </div>

      <div class="item inforMan">
        <div class="item-title">通知人</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_InformMan"
          placeholder="请选择通知人"
          readonly
          @click="isShowInforManSelect = true"
        />
      </div>

      <div class="item expressCost">
        <div class="item-title">快递费用</div>
        <input
          class="item-value"
          v-model="newExpress.ExpressRegistration_Cost"
          placeholder="请输入快递费用"
        />
      </div>

      <div class="item consignee">
        <div class="item-title">收货人信息</div>
        <textarea
          class="item-value"
          v-model="newExpress.ExpressRegistration_ConsigneeInfor"
          placeholder="请输入收货人信息"
        ></textarea>
      </div>

      <div class="item mailService">
        <div class="item-title">邮寄物品</div>
        <textarea
          class="item-value"
          v-model="newExpress.ExpressRegistration_MailService"
          placeholder="请输入邮寄物品"
        ></textarea>
      </div>
    </div>

    <button class="save" @click="updateExpress">保存</button>

    <van-popup v-model="isShowInforManSelect" position="bottom">
      <div class="formanSelect">
        <div class="buttons">
          <button id="formanSelectCancel" @click="clearInformman">清空</button>
          <button id="formanSelectConfirm" @click="formanSelectConfirm">
            确定
          </button>
        </div>
        <div class="options">
          <div
            class="option"
            v-for="(item, index) in colleagueList"
            :key="index"
          >
            <van-checkbox v-model="item.check" checked-color="#3c43e3">{{
              item.name
            }}</van-checkbox>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="isShowMailTimeSelect" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择寄件时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="mailTimeSelect"
        @cancel="isShowMailTimeSelect = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "EditExpress",
  data() {
    return {
      newExpress: {},
      isShowInforManSelect: false,
      isShowMailTimeSelect: false,
      projectList: [
        "广州健新科技股份有限公司",
        "杭州巨星科技有限公司",
        "河北奎山水泥集团有限公司",
        "九号有限公司",
        "厦门合嘉源生活服务集团有限责任公司",
        "丽达集团",
        "南方电网",
        "翔龙集团",
        "宁德东侨国有资产投资建设有限公司",
        "浙江红蜻蜓鞋业股份有限公司",
        "河钢集团有限公司",
        "中电建生态环境集团有限公司",
        "中山公用水务有限公司",
        "中车株洲电力机车有限公司",
        "甘肃上峰水泥股份有限公司",
        "广州市卡宾服饰有限公司",
        "京蓝",
        "理昂生态能源",
        "厦门象屿",
        "陕西省地方电力（集团）",
        "成都成量工具集团有限公司",
        "杭州大搜车汽车服务有限公司",
        "北京新合作商业管理有限公司",
        "广物汽贸集团",
      ],
      colleagueList: [],
      searchResult: [],
      date: "",
      year: "",
      month: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
    };
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },

    //更新快递信息
    updateExpress() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "快递信息更新中...",
        loadingType: "spinner",
      });

      let that = this;

      let fd = new FormData();

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let info = {
        IsSystem: false,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "Update_ExpressRegistration",
          },
          {
            json: JSON.stringify([
              {
                ExpressRegistration_ProjectName: this.newExpress
                  .ExpressRegistration_ProjectName,
              },
              {
                ExpressRegistration_ContractNum: this.newExpress
                  .ExpressRegistration_ContractNum,
              },
              {
                ExpressRegistration_MailTime: this.newExpress
                  .ExpressRegistration_MailTime,
              },
              {
                ExpressRegistration_SenderName: this.newExpress
                  .ExpressRegistration_SenderName,
              },
              {
                ExpressRegistration_InformMan: this.newExpress
                  .ExpressRegistration_InformMan,
              },
              {
                ExpressRegistration_Cost: this.newExpress
                  .ExpressRegistration_Cost,
              },
              {
                ExpressRegistration_ConsigneeInfor: this.newExpress
                  .ExpressRegistration_ConsigneeInfor,
              },
              {
                ExpressRegistration_MailService: this.newExpress
                  .ExpressRegistration_MailService,
              },
            ]),
            ExpressRegistration_ID: this.newExpress.ExpressRegistration_ID,
          },
        ],
      };

      fd.append("inputJson", JSON.stringify(info));

      this.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralOpreateData",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          if (res.data.errorCode == "1") {
            Toast({
              message: "修改成功",
              forbidClick: true,
            });

            sessionStorage.removeItem("currentExpressDetail");

            that.$store.commit("setKeepAlive", []);

            setTimeout(() => {
              that.$router.replace("/");
            }, 1500);
          } else {
            Toast(res.data.errorText);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },

    // 选择通知人取消
    clearInformman() {
      this.newExpress.informman = "无通知人";
      this.showInformmanSelect = false;
      this.result = [];
    },

    // 选择通知人确定
    formanSelectConfirm() {
      var formanSelectArr = this.colleagueList.filter((e) => {
        return e.check;
      });
      var str = "";

      formanSelectArr.forEach((element, index) => {
        console.log(element.check);
        if (element.check) {
          if (index < formanSelectArr.length - 1) {
            str = str + element.name + "、";
          } else if (index == formanSelectArr.length - 1) {
            str = str + element.name;
          }
        }
      });

      this.newExpress.ExpressRegistration_InformMan = str;
      this.isShowInforManSelect = false;
    },

    // 选择寄件时间
    mailTimeSelect(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth();
      var d = time.getDate();

      if (m < 10) {
        m = "0" + m;
      }

      if (d < 10) {
        d = "0" + d;
      }
      console.log(y, m, d);

      this.newExpress.ExpressRegistration_MailTime = y + "-" + m + "-" + d;
      this.isShowMailTimeSelect = false;
    },
  },
  created() {
    if (sessionStorage.getItem("currentExpressDetail") != {}) {
      var express = JSON.parse(sessionStorage.getItem("currentExpressDetail"));

      express.ExpressRegistration_MailTime = express.ExpressRegistration_MailTime.split(
        "T"
      )[0];

      if (express.SignForTime) {
        express.SignForTime = express.SignForTime.replace("T", " ");
        express.SignForTime = express.SignForTime.split(".")[0];
      }

      this.newExpress = JSON.parse(JSON.stringify(express));
      this.currentDate = new Date(this.newExpress.MailTime);
      this.month = express.ExpressRegistration_MailTime.split("-")[1];
      this.year = express.ExpressRegistration_MailTime.split("-")[0];

      if (this.$store.state.colleagueList) {
        this.colleagueList = this.$store.state.colleagueList;
      }
    } else {
      Toast("数据获取失败");
      var that = this;
      setTimeout(() => {
        that.goback();
      }, 1500);
    }
  },
};
</script>

<style lang="less">
.editExpress {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  .wrap {
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  .wrap .item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    line-height: 40px;
    border-bottom: 1px solid #eff0f4;
    display: flex;
  }

  .wrap .item .item-title {
    font-size: 14px;
    color: #333333;
    text-align: left;
    margin-right: 50px;
  }

  .wrap .item .item-value {
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: right;
    color: #999999;
    font-size: 14px;
  }

  .wrap .item .section {
    flex: 1;
    text-align: right;
  }

  .wrap .item textarea.item-value {
    height: 100px;
    line-height: 20px;
    padding: 10px 0;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .expressNum .item-title .update {
    height: 20px;
    line-height: 16px;
    float: right;
    padding: 2px 5px;
    font-size: 14px;
    color: #ffffff;
    background-color: #3c43e3;
  }

  .expressNum .item-title .update image {
    width: 16px;
    height: 16px;
    margin-right: -2px;
    vertical-align: middle;
    outline: 0;
    border: 0;
  }

  .expressNum .wrap {
    width: 100%;
    height: 30px;
    display: flex;
  }

  .expressNum .wrap .item-value {
    flex: 1;
    line-height: 30px;
  }

  .expressNum .wrap image {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .signForTime .item-title .received {
    float: right;
  }

  .consignee .item-value {
    min-height: 60px;
  }

  .mailService .item-value {
    min-height: 60px;
  }

  .save {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #ffffff;
    background-color: #3c43e3;
    text-align: center;
    margin-top: 20px;
    outline: 0;
    border: 0;
  }

  .formanSelect {
    /* text-align: center; */
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }

  .formanSelect .buttons {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .formanSelect .buttons button#formanSelectCancel {
    float: left;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 5px 13px;
    border-radius: 5px;
  }

  .formanSelect .buttons button#formanSelectConfirm {
    float: right;
    color: #ffffff;
    background-color: #3c43e3;
    border: 1px solid #3c43e3;
    padding: 5px 13px;
    border-radius: 5px;
  }

  .formanSelect .options {
    width: 100%;
    height: 300px;
    overflow-y: auto;
  }

  .formanSelect .options .option {
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .formanSelect .options .option .van-checkbox__label {
    flex: 1;
    text-align: left;
  }
}
</style>