<!-- 快递统计页面 -->
<template>
  <div class="expressStatistical">
    <div class="header">
      <div class="navigation">
        <div class="icon" @click="show = true">我</div>
        <span class="title">快递统计</span>
      </div>
    </div>

    <van-pull-refresh v-model="isRefreshLoading" @refresh="refreshExpress">
      <div class="content" v-if="!isloading">
        <div class="content-details" v-if="express.length != 0">
          <div
            class="expressWrap"
            v-for="(item, index) in express"
            :key="index"
          >
            <div class="monthTitle">{{ item.year }}年{{ item.month }}月</div>
            <div
              class="monthCard"
              @click="toMonthDetail(index)"
              v-if="item.data.length != 0"
            >
              <div class="left monthAvatar">{{ item.month }}月</div>
              <div class="middle data">
                <div class="num">共{{ item.data.length }}个快递</div>
                <div class="dataStatistics">
                  <span class="signFor"> {{ item.signForNum }}个已签收 </span>
                  <span class="unSignFor">
                    {{ item.unSignForNum }}个未签收
                  </span>
                  <span class="overTime" v-if="item.overTimeNum > 0">
                    （{{ item.overTimeNum }}个已超时）
                  </span>
                </div>
              </div>
              <div class="right cost">{{ item.totalCost }}元</div>
            </div>
            <div class="noExpressHint" v-else>
              <Hint :text="item.year + '年' + item.month + '月暂无快递'"></Hint>
            </div>
          </div>
        </div>
        <div class="loadWrap">
          <button id="loadMore" @click="loadMore" v-if="isMore">
            点击加载更多
          </button>
          <div class="noMore" v-else>没有更多了</div>
        </div>
      </div>

      <div class="loadingWrap" v-else>
        <van-loading type="spinner" color="#666666" />
      </div>
    </van-pull-refresh>

    <van-popup
      v-model="show"
      position="left"
      :style="{
        width: '70%',
        height: '100%',
      }"
    >
      <div class="data-header">
        <div class="left avatar">{{ userInfo.userName.split("")[0] }}</div>
        <div class="right infor">
          <div class="name">{{ userInfo.userName }}</div>
          <div class="role">{{ userInfo.role }}</div>
        </div>
      </div>
      <div class="function">
        <div class="item" @click="toUserInfo">
          <img src="../assets/express_Image/userInfoIcon.png" /><span
            >我的信息</span
          >
        </div>
        <!-- <div class="item" @click="toSetting">
          <img src="../assets/express_Image/settingIcon.png" /><span
            >设置
          </span>
        </div> -->
        <div class="item" @click="exit">
          <img src="../assets/express_Image/exitIcon.png" /><span
            >退出登录</span
          >
        </div>
      </div>
    </van-popup>
    <van-dialog id="van-dialog" confirm-button-color="#1989fa" />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import Hint from "../components/Hint";

export default {
  name: "ExpressStatistical",
  components: { Hint },
  data() {
    return {
      userInfo: {},
      express: [],
      currentYear: "",
      currentMonth: "",
      year: "",
      month: "",
      index: 0,
      show: false,
      currentDate: "",
      isloading: false,
      isRefreshLoading: false,
      isMore: true,
    };
  },
  methods: {
    //获取所有快递信息
    getExpress(year, month) {
      let fd = new FormData();

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "SignBoxExpressRegistration",
          },
          {
            year: year,
            month: month,
            ExpressRegistration_EnterpriseID: JSON.parse(
              sessionStorage.getItem("userInfo")
            ).orgID,
            ExpressRegistration_Founder: JSON.parse(
              sessionStorage.getItem("userInfo")
            ).userName,
            ExpressRegistration_IsSignFor: "",
          },
        ],
      };

      fd.append("inputJson", JSON.stringify(info));
      fd.append("limit", 1000);
      fd.append("page", 1);

      this.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryDatas",
          fd,
          config
        )
        .then((res) => {
          console.log(res);
          if (res.data.errorCode == "1") {
            var signForNum = 0;
            var unSignForNum = 0;
            var overTimeNum = 0;
            var totalCost = 0;

            for (let index = 0; index < res.data.data.Table.length; index++) {
              let element = res.data.data.Table[index];
              if (element.ExpressRegistration_IsSignFor === "true") {
                signForNum++;
              } else if (element.ExpressRegistration_IsSignFor === "false") {
                unSignForNum++;

                if (
                  Number(element.ExpressRegistration_OverTime) -
                    Number(element.ExpressRegistration_SendOffTime) <
                  0
                ) {
                  overTimeNum++;
                }
              }

              element.ExpressRegistration_OverTime = Number(
                element.ExpressRegistration_OverTime
              );
              element.ExpressRegistration_SendOffTime = Number(
                element.ExpressRegistration_SendOffTime
              );
              totalCost = totalCost + Number(element.ExpressRegistration_Cost);
            }

            var express = {
              year: year,
              month: month,
              data: res.data.data.Table,
              signForNum: signForNum,
              unSignForNum: unSignForNum,
              overTimeNum: overTimeNum,
              totalCost: totalCost,
            };

            this.express.push(express);

            var count = res.data.data.Table1[0].count;

            console.log(count);

            // for (let index = 0; index < this.express.length; index++) {
            //   let element = this.express[index].data;
            //   count = count - element.length;
            // }

            if (count <= 0) {
              this.isMore = false;
            }
          } else {
            Toast(res.data.errorText);
          }
          Toast.clear();
          this.isloading = false;
          this.isRefreshLoading = false;
        })
        .catch((err) => {
          Toast(err);
          Toast.clear();
          this.isloading = false;
          this.isRefreshLoading = false;
        });
    },

    //跳转到个人信息页面
    toUserInfo() {
      this.$router.push("/userInfo");
      this.show - false;
    },

    //跳转到超时提醒设置页面
    toSetting() {
      this.$router.push("/setting");
    },

    // 退出登录
    exit() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定需要退出登录吗？",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          this.show = false;
        })
        .catch(() => {
          // on cancel
        });
    },

    //路由跳转到快递月份分类页面
    toMonthDetail(index) {
      this.$store.commit("setCurrentMonthExpress", this.express[index]);
      this.$router.push("/monthClassifyExpress");
    },

    //加载更多数据
    loadMore() {
      Toast.loading({
        message: "数据正在加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      if (this.month > 1) {
        this.month = this.month - 1;
      } else {
        this.year = this.year - 1;
        this.month = 12;
      }

      this.getExpress(this.year, this.month);
    },

    // 下拉刷新
    refreshExpress() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;

      this.currentDate = year + "-" + month;

      this.currentYear = year;

      this.year = year;

      this.month = month;

      this.express = [];

      this.isMore = true;

      this.getExpress(year, month);
    },
  },
  created() {
    this.isloading = true;

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    this.currentDate = year + "-" + month;

    this.currentYear = year;

    this.currentMonth = month;

    this.year = year;

    this.month = month;

    this.getExpress(year, month);

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
};
</script>

<style lang="less">
.expressStatistical {
  width: 100vw;
  min-height: 100vh;

  /* 头部 */
  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    text-align: center;
    box-sizing: border-box;
    /* border-bottom: 1px solid #999; */
    color: #fff;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .navigation {
    width: 100%;
    height: 44px;
    background-color: #3c43e3;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
  }

  .header .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    background-color: #fff;
    color: #3c43e3;
    box-sizing: border-box;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  /* 内容 */
  .content {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 10px 20px;
    padding-bottom: 50px;
    padding-top: 51px;
    background-color: #f6f6f6;
    position: relative;
  }

  .content .content-details {
    width: 100%;
    /* min-height: 100vh; */

    .expressWrap {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .content .content-details .monthTitle {
    display: inline-block;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 5px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    margin-bottom: 10px;
  }

  .content .content-details .monthCard {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    /* border: 1px solid #333; */
    box-shadow: 0 1px 5px #cfcfcf;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 13px;
    color: #333333;
    border-radius: 10px;
  }

  .monthCard .left {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #3c43e3;
    margin-right: 10px;
  }

  .monthCard .middle {
    flex: 1;
    text-align: left;
  }

  .monthCard .middle .num {
    height: 20px;
    padding-top: 10px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #333333;
  }

  .monthCard .middle .dataStatistics {
    font-size: 12px;
    line-height: 12px;
    color: #999999;
  }

  .monthCard .middle .dataStatistics span {
    display: inline-block;
    margin-right: 4px;
    line-height: 11px;
  }

  .monthCard .right {
    padding-top: 5px;
    font-size: 15px;
  }

  .noExpressHint {
    width: 100%;
    height: 30px;
  }

  .content .loadWrap {
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    // position: absolute;
    // bottom: 10px;
    // left: 20px;
  }

  .loadWrap #loadMore {
    width: 100%;
    height: 100%;
    line-height: 36px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    background-color: #3c43e3;
    border: 0;
    outline: 0;
    border-radius: 5px;
  }

  .loadWrap .noMore {
    width: 100%;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #666666;
  }

  /* loading样式 */
  .loadingWrap {
    padding-top: 100px;
    text-align: center;
  }

  /* 到底提示 */
  .bottomHint {
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #666666;
  }

  /* 左边弹窗 */
  .van-popup {
    box-sizing: border-box;
    padding: 20px;
  }

  .van-popup .data-header {
    padding-top: 10px;
    width: 100%;
    height: 80px;
  }

  .van-popup .data-header .left {
    display: inline-block;
    width: 80px;
    height: 80px;
    vertical-align: middle;
    margin-right: 15px;
    border-radius: 50%;
    background-color: #3c43e3;
    color: #fff;
    text-align: center;
    line-height: 80px;
    font-size: 24px;
    font-weight: bold;
  }

  .van-popup .data-header .right {
    display: inline-block;
    vertical-align: middle;
  }

  .van-popup .data-header .right .name {
    line-height: 35px;
    font-size: 20px;
  }

  .van-popup .data-header .right .role {
    line-height: 20px;
    font-size: 14px;
  }

  .van-popup .function {
    margin-top: 10px;
    width: 100%;
  }

  .van-popup .function .item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #333;
    border-bottom: 1px solid #e3e3e3;
  }

  .van-popup .function .item img {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: middle;
  }

  .van-popup .function .item span {
    color: #333333;
    vertical-align: middle;
    font-size: 14px;
  }
}
</style>