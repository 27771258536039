<!-- 提示 类型1 -->
<template>
  <div class="hint">
    <img class="icon" src="../assets/express_Image/noproject.png" />
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Hint",
  props: {
    text: String,
  },
};
</script>

<style lang="less">
.hint {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: block;
  box-sizing: border-box;
  text-align: center;
  color: #999999;
  font-size: 14px;
  margin: 0 auto;
}

.hint .icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.hint .text {
  vertical-align: middle;
}
</style>