<!-- 设置页面 -->
<template>
  <div class="setting">
    <div class="header">
      <div class="close">
        <van-icon name="arrow-left" @click="goback" />
      </div>
      <span>设置</span>
    </div>

    <div class="timeSetting">
      <div class="wrap">
        <div class="title">超时提醒时间</div>
        <div class="value">
          <input placeholder="请输入超时提醒时间" v-model="overTime" />
        </div>
        <div class="unit">天</div>
      </div>
      <button class="saveOverTime" type="info" @click="saveOverTime">
        保存
      </button>
    </div>
    <van-dialog id="van-dialog" confirm-button-color="#3c43e3" />
  </div>
</template>

<script>
export default {
  name: "Setting",
  data() {
    return {
      overTime: 0,
    };
  },
  methods: {
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },

    // 超时时间设置
    saveOverTime() {},
  },
  created() {},
};
</script>

<style lang="less">
.setting {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;

    .close {
      font-size: 20px;
      color: #ffffff;
      line-height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
    }
  }

  .timeSetting {
    width: 100vw;
    padding: 15px 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }

  .timeSetting .wrap {
    width: 100%;
    padding: 10px;
    // border-radius: 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 30px;
    background-color: #ffffff;
    // box-shadow: 0px 1px 5px #cfcfcf;
    display: flex;
  }

  .timeSetting .title {
    font-size: 14px;
    color: #333333;
    margin-right: 10px;
  }

  .timeSetting .value {
    flex: 1;
    text-align: right;

    input {
      text-align: right;
    }
  }

  .timeSetting .value input {
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
  }

  .timeSetting .saveOverTime {
    width: calc(100% - 40px);
    height: 36px;
    color: #ffffff;
    background-color: #3c43e3;
    position: fixed;
    bottom: 10px;
    left: 20px;
  }
}
</style>