import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        keepAlive: ["Home"],
        currentMonthExpress: [],
        currentSearchResult: [],
        colleagueList: []
    },
    mutations: {
        // 设置keep-alive缓存的页面
        setKeepAlive: (state, keepAlive) => {
            state.keepAlive = keepAlive;
            console.log(state.keepAlive);
        },

        // 设置当前展示的某年某月快递
        setCurrentMonthExpress: (state, monthExpress) => {
            console.log(monthExpress);
            state.currentMonthExpress = monthExpress
            console.log(state.currentMonthExpress);
        },

        // 设置搜索结果
        setCcurrentSearchResult: (state, array) => {
            state.currentSearchResult = array
        },

        // 设置公司同事列表
        setColleagueList: (state, array) => {
            state.colleagueList = array
        }
    },
    getters: {
        keepAlive: state => state.keepAlive,
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})