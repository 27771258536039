<!-- 快递登记页面 -->
<template>
  <div class="expressRegistration">
    <div class="header">
      <span>快递登记</span>
    </div>

    <template v-if="!isLoading">
      <div class="register" @click="closeHintOptions">
        <div class="wrap">
          <div class="item expressNum">
            <div class="title">快递单号</div>
            <div class="value">
              <input
                type="text"
                class="expressNum-input"
                v-model="newExpress.expressnum"
                placeholder="扫一扫输入快递单号"
              />
              <img
                src="../assets/express_Image/saoyisao.png"
                @click="scancCode"
              />
            </div>
          </div>
          <div class="item project">
            <div class="title">项目</div>
            <div class="value" v-if="mode == 'select'">
              <input
                type="text"
                placeholder="请选择项目名称"
                v-model="newExpress.projectname"
                readonly
                @click="isShowProjectSelect = true"
              />
              <!-- <button class="Mode" @click="switchModeToInput">选择</button> -->
            </div>

            <div class="value" v-else>
              <input
                type="text"
                class="projectName-input"
                @input="getProjectName"
                placeholder="点击输入项目名称"
                v-model="newExpress.projectname"
              />
              <!-- <button class="Mode" @click="switchModeToSelect">输入</button> -->
              <div
                class="hintOptions"
                v-if="showHint && hintOptions.length > 0"
              >
                <div
                  class="option"
                  v-for="(item, index) in hintOptions"
                  :key="index"
                  @click="setProjectName(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="item contractNum">
            <div class="title">合同号</div>
            <div class="value">
              <input
                type="text"
                class="contractNum-input"
                placeholder="点击输入合同号"
                v-model="newExpress.contractnum"
              />
            </div>
          </div>

          <div class="item stub">
            <div class="title">上传快递存根</div>
            <div class="value">
              <div class="imageWrap" v-if="fileList.length > 0">
                <div class="deleteImage">
                  <van-icon
                    name="clear"
                    color="#000000ee"
                    size="24"
                    @click="deleteImage"
                  />
                </div>
                <van-image
                  width="80px"
                  height="80px"
                  fit="cover"
                  :src="fileList[0].url"
                  @click="showBigImage(fileList[0].url)"
                />
              </div>
              <div class="uploadImage" v-else>
                <img src="../assets/express_Image/cameraIcon.png" alt="" />
                <input
                  type="file"
                  @change="tirggerFile"
                  accept="image/*"
                  capture="camera"
                  ref="expressImage"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="wrap">
          <div class="item sender">
            <div class="title">寄件人</div>
            <div class="value">
              <input
                type="text"
                class="sender-input"
                placeholder="点击输入寄件人姓名"
                v-model="newExpress.sendername"
              />
            </div>
          </div>
          <div class="item project">
            <div class="title">通知人</div>
            <div
              :class="
                newExpress.informman == '无通知人' ? 'value active' : 'value'
              "
              @click="isShowFormerSelect = true"
            >
              <input
                type="text"
                placeholder="请选择通知人"
                readonly
                v-model="newExpress.informman"
              />
            </div>
          </div>
          <div class="item cost">
            <div class="title">快递费用</div>
            <div class="value">
              <input
                type="text"
                class="cost-input"
                placeholder="点击输入快递费用"
                v-model="newExpress.cost"
              />
            </div>
          </div>
          <div class="item mailTime">
            <div class="title">寄件时间</div>
            <div class="section">
              <input
                type="text"
                placeholder="请选择寄件时间"
                v-model="newExpress.mailTime"
                readonly
                @click="isShowMailTimeSelect = true"
              />
            </div>
          </div>
          <div class="item recipients">
            <div class="title">收件人信息</div>
            <div class="value">
              <textarea
                class="recipients-textarea"
                v-model="newExpress.consigneeinfor"
              ></textarea>
            </div>
          </div>
          <div class="item article">
            <div class="title">物品</div>
            <div class="value">
              <textarea
                class="article-textarea"
                v-model="newExpress.mailservice"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="buttons">
          <van-button type="default" @click="clear">清空</van-button>
          <van-button type="info" @click="submitExpress" color="#3c43e3"
            >提交</van-button
          >
        </div>
      </div>
      <van-dialog id="van-dialog" confirm-button-color="#3c43e3" />
    </template>

    <van-popup v-model="isShowProjectSelect" position="bottom">
      <van-picker
        title="项目"
        show-toolbar
        :columns="projectList"
        @confirm="pickerConfirm"
        @cancel="isShowProjectSelect = false"
      />
    </van-popup>

    <van-popup v-model="isShowMailTimeSelect" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择寄件时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="mailTimeSelect"
        @cancel="isShowMailTimeSelect = false"
      />
    </van-popup>

    <van-popup v-model="isShowFormerSelect" position="bottom">
      <div class="formanSelect">
        <div class="buttons">
          <button id="formanSelectCancel" @click="clearInformman">清空</button>
          <button id="formanSelectConfirm" @click="formanSelectConfirm">
            确定
          </button>
        </div>
        <div class="options">
          <div
            class="option"
            v-for="(item, index) in colleagueList"
            :key="index"
          >
            <van-checkbox v-model="item.check" checked-color="#3c43e3">{{
              item.name
            }}</van-checkbox>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog, ImagePreview } from "vant";
import lrz from "lrz";
import wx from "weixin-js-sdk";

export default {
  name: "ExpressRegistration",
  data() {
    return {
      userInfo: {},
      fileList: [],
      projectList: [
        "无项目",
        "广州健新科技股份有限公司",
        "杭州巨星科技有限公司",
        "河北奎山水泥集团有限公司",
        "九号有限公司",
        "厦门合嘉源生活服务集团有限责任公司",
        "丽达集团",
        "南方电网",
        "翔龙集团",
        "宁德东侨国有资产投资建设有限公司",
        "浙江红蜻蜓鞋业股份有限公司",
        "河钢集团有限公司",
        "中电建生态环境集团有限公司",
        "中山公用水务有限公司",
        "中车株洲电力机车有限公司",
        "甘肃上峰水泥股份有限公司",
        "广州市卡宾服饰有限公司",
        "京蓝",
        "理昂生态能源",
        "厦门象屿",
        "陕西省地方电力（集团）",
        "成都成量工具集团有限公司",
        "杭州大搜车汽车服务有限公司",
        "北京新合作商业管理有限公司",
        "广物汽贸集团",
      ],
      colleagueList: [
        {
          name: "熊娟",
          check: false,
        },
        {
          name: "韩彩虹",
          check: false,
        },
        {
          name: "伍宏丹",
          check: false,
        },
        {
          name: "江凤仪",
          check: false,
        },
        {
          name: "管理员",
          check: false,
        },
        {
          name: "谭添信",
          check: false,
        },
        {
          name: "周咏连",
          check: false,
        },
        {
          name: "彭美施",
          check: false,
        },
        {
          name: "黄敬涛",
          check: false,
        },
        {
          name: "林杏华",
          check: false,
        },
        {
          name: "宋伟",
          check: false,
        },
        {
          name: "刘仕伟",
          check: false,
        },
        {
          name: "李阳",
          check: false,
        },
        {
          name: "谭智君",
          check: false,
        },
        {
          name: "李凯欣",
          check: false,
        },
        {
          name: "梁健",
          check: false,
        },
        {
          name: "刘志军",
          check: false,
        },
        {
          name: "刘春荣",
          check: false,
        },
      ],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      hintOptions: [],
      index: 0,
      index1: 0,
      isLoading: false,
      isShowProjectSelect: false,
      isShowMailTimeSelect: false,
      isShowFormerSelect: false,
      isChoiseMailTime: false,
      showHint: false,
      showInformmanSelect: false,
      isOis: false,
      newExpress: {
        expressnum: "",
        contractnum: "",
        projectname: "",
        mailservice: "",
        sendername: "",
        mailTime: "",
        consigneeinfor: "",
        cost: "",
        informman: "无通知人",
        UserID: "",
      },
      overTime: 5,
      mode: "input",
      result: [],
    };
  },
  methods: {
    //获取所有项目列表
    getProjectList() {
      this.$axios
        .post("https://emonitor.docuvix.com:8088/GetProjectName")
        .then((res) => {
          if (JSON.parse(res.data).code && JSON.parse(res.data).code == "200") {
            var ProjectList = result.data.data;
            ProjectList.unshift("无项目");
            this.projectList = ProjectList;
          } else {
            Toast("项目数据获取失败，请手动输入项目名称或重新登录");
          }
        })
        .catch((err) => {});
    },

    //获取所有同事列表
    getColleagueList() {
      var that = this;

      var fd = new FormData();

      var config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      let info = {
        IsSystem: false,
        IsPagination: true,
        data: [
          {
            "@ClassName": "peacepay",
            "@MethodName": "GetUserAndRole",
          },
          {
            Account: "",
            Name: "",
            Password: "",
            UserIdentity: "",
            OrgID: JSON.parse(sessionStorage.getItem("userInfo")).orgID,
            IsGroup: 0,
            IsDisable: 0,
            IsLocked: 0,
            PhoneNum: "",
            Email: "",
            Remarks: "",
            Text1: "",
            Text2: "",
            Text3: "",
            Text4: "",
            Text5: "",
            Rid: "",
          },
        ],
      };

      fd.append("inputJson", JSON.stringify(info));
      fd.append("limit", 1000);
      fd.append("page", 1);

      this.$axios
        .post(
          "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralQueryData",
          fd,
          config
        )
        .then((res) => {
          if (res.data.errorCode == "1") {
            var colleagueArr = [];
            res.data.data.forEach((element) => {
              var obj = {
                name: element.Name,
                check: false,
              };

              colleagueArr.push(obj);
            });

            this.$store.commit("setColleagueList", colleagueArr);
            that.colleagueList = colleagueArr;
          } else {
            Toast(res.data.errorText);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },

    // 获取暂存信息
    getTemporaryStorage() {
      if (!localStorage.getItem("TemporaryStorageArr")) {
        return;
      }

      var data = null;

      var TemporaryStorageArr = JSON.parse(
        localStorage.getItem("TemporaryStorageArr")
      );

      for (let index = 0; index < TemporaryStorageArr.length; index++) {
        var element = TemporaryStorageArr[index];

        if (
          element.UserID === JSON.parse(sessionStorage.getItem("userInfo")).id
        ) {
          data = element;
        }
      }

      if (data) {
        this.newExpress = {
          expressnum: data.expressnum,
          contractnum: data.contractnum,
          projectname: data.projectname,
          mailservice: data.mailservice,
          sendername: data.sendername,
          mailTime: data.mailTime,
          consigneeinfor: data.consigneeinfor,
          cost: data.cost,
          informman: data.informman,
          UserID: data.UserID,
        };
      }
    },

    // 设置暂存信息
    setTemporaryStorage() {
      console.log("1");
      if (
        this.newExpress.expressnum == "" &&
        this.newExpress.contractnum == "" &&
        (this.newExpress.projectname == "" ||
          this.newExpress.projectname == "无项目") &&
        this.newExpress.mailservice == "" &&
        this.newExpress.sendername == "" &&
        this.newExpress.mailTime == "" &&
        this.newExpress.consigneeinfor == "" &&
        this.newExpress.cost == "" &&
        (this.newExpress.informman == "" ||
          this.newExpress.informman == "无通知人")
      ) {
        if (localStorage.getItem("TemporaryStorageArr")) {
          var TemporaryStorageArr = JSON.parse(
            localStorage.getItem("TemporaryStorageArr")
          );

          for (let index = 0; index < TemporaryStorageArr.length; index++) {
            var element = TemporaryStorageArr[index];

            if (
              element.UserID ===
              JSON.parse(sessionStorage.getItem("userInfo")).id
            ) {
              TemporaryStorageArr.splice(index, 1);
            }
          }

          localStorage.setItem(
            "TemporaryStorageArr",
            JSON.stringify(TemporaryStorageArr)
          );

          console.log("oiuyt");
        }
      } else {
        if (localStorage.getItem("TemporaryStorageArr")) {
          var TemporaryStorageArr = JSON.parse(
            localStorage.getItem("TemporaryStorageArr")
          );

          for (let index = 0; index < TemporaryStorageArr.length; index++) {
            var element = TemporaryStorageArr[index];

            if (
              element.UserID ===
              JSON.parse(sessionStorage.getItem("userInfo")).id
            ) {
              console.log("eeeeeee");
              TemporaryStorageArr.splice(index, 1);
            }
          }

          this.newExpress.UserID = JSON.parse(
            sessionStorage.getItem("userInfo")
          ).id;

          TemporaryStorageArr.push(this.newExpress);

          localStorage.setItem(
            "TemporaryStorageArr",
            JSON.stringify(TemporaryStorageArr)
          );
        } else {
          var arr = [];

          arr.push({
            expressnum: this.newExpress.expressnum,
            contractnum: this.newExpress.contractnum,
            projectname: this.newExpress.projectname,
            mailservice: this.newExpress.mailservice,
            sendername: this.newExpress.sendername,
            mailtime: this.newExpress.mailTime,
            consigneeinfor: this.newExpress.consigneeinfor,
            cost: this.newExpress.cost,
            informman: this.newExpress.informman,
            username: this.userInfo.userName,
            UserID: JSON.parse(sessionStorage.getItem("userInfo")).id,
          });

          localStorage.setItem("TemporaryStorageArr", JSON.stringify(arr));
        }
      }
    },

    // 删除暂存信息
    deleteTemporaryStorage() {
      var TemporaryStorageArr = JSON.parse(
        localStorage.getItem("TemporaryStorageArr")
      );

      for (let index = 0; index < TemporaryStorageArr.length; index++) {
        var element = TemporaryStorageArr[index];

        if (
          element.UserID === JSON.parse(sessionStorage.getItem("userInfo")).id
        ) {
          TemporaryStorageArr.splice(index, 1);
          break;
        }
      }

      localStorage.setItem(
        "TemporaryStorageArr",
        JSON.stringify(TemporaryStorageArr)
      );
    },

    // 选择项目确定
    pickerConfirm(value) {
      console.log(value);
      this.newExpress.projectname = value;
      this.isShowProjectSelect = false;
    },

    // 点击提示选择项目名称
    setProjectName(projectName) {
      this.newExpress.projectname = projectName;
      this.hintOptions = [];
    },

    // base64转成文件
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },

    // 文件上传
    tirggerFile($event) {
      if ($event.target.files.length > 0) {
        var that = this;
        console.log($event.target.files);
        if ($event.target.files[0].type == "application/pdf") {
          Toast("请上传图片");
        } else {
          Toast.loading({
            message: "正在上传图片",
            forbidClick: true,
            loadingType: "spinner",
            duration: 0,
          });

          lrz($event.target.files[0], { quality: 0.2 }).then(function (
            results
          ) {
            console.log(results);

            let file = that.base64ImgtoFile(results.base64);

            let reader = new FileReader();

            reader.readAsDataURL(file);

            //操作完成
            reader.onload = function (e) {
              // file 对象的属性
              let src = reader.result;

              var obj = {
                file: file,
                url: src,
                savePath: "",
              };

              let fd = new FormData();
              fd.append("files", file);
              let config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              that.$axios
                .post(
                  "https://beyondsky.docuvix.com/peacepay/api/Home/UploadImages",
                  fd,
                  config
                )
                .then((res) => {
                  Toast.clear();
                  console.log(res.data);
                  if (res.data.errorCode == "1") {
                    obj.savePath = res.data.data[0];
                    that.fileList.push(obj);
                    Toast("上传完成");
                  } else {
                    Toast("文件上传失败，" + res.data.errorText);
                  }

                  console.log(that.$refs);

                  that.$refs.expressImage.value = null;
                })
                .catch((err) => {
                  Toast("文件上传失败，" + err);
                  that.$refs.expressImage.value = null;
                });
            };
          });
        }
      }
    },

    // 显示大图
    showBigImage(url) {
      ImagePreview({
        images: [url],
        startPosition: 0,
      });
    },

    // 删除上传的存根图片
    deleteImage() {
      Dialog.confirm({
        title: "温馨提示",
        message: "存根图片暂未保存，您确定需要删除吗？",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          this.fileList = [];
        })
        .catch(() => {
          // on cancel
        });
    },

    // 选择寄件时间
    mailTimeSelect(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();

      if (m < 10) {
        m = "0" + m;
      }

      if (d < 10) {
        d = "0" + d;
      }
      console.log(y, m, d);

      this.newExpress.mailTime = y + "-" + m + "-" + d;
      this.isShowMailTimeSelect = false;
    },

    // 选择通知人
    formanSelectConfirm() {
      var formanSelectArr = this.colleagueList.filter((e) => {
        return e.check;
      });
      var str = "";

      formanSelectArr.forEach((element, index) => {
        console.log(element.check);
        if (element.check) {
          if (index < formanSelectArr.length - 1) {
            str = str + element.name + "、";
          } else if (index == formanSelectArr.length - 1) {
            str = str + element.name;
          }
        }
      });

      this.newExpress.informman = str;
      this.isShowFormerSelect = false;
    },

    //扫一扫
    scancCode() {
      var that = this;
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res);
          that.newExpress.expressnum = res.resultStr.split(",")[1];
        },
      });
    },

    // 显示通知人选择
    showSelect() {
      this.showInformmanSelect = true;
    },

    // 关闭通知人选择
    onClose() {
      this.showInformmanSelect = false;
    },

    onChange(event) {
      this.result = event.detail;
    },

    confirmInformman() {
      var informman = "";
      for (let index = 0; index < this.result.length; index++) {
        if (informman == "") {
          informman = this.result[index];
        } else {
          informman = informman + "、" + this.result[index];
        }
      }
      this.newExpress.informman = informman;
      this.showInformmanSelect = false;
    },

    clearInformman() {
      this.newExpress.informman = "无通知人";
      this.showInformmanSelect = false;
      this.result = [];
    },

    //项目名称选项发生改变时执行方法
    bindPickerChange(e) {
      console.log(e.detail.value);
      this.index = e.detail.value;
      this.newExpress.projectname = this.projectList[this.index];
    },

    //通知人选项发生改变时执行方法
    bindPickerChange1(e) {
      console.log(e.detail.value);
      this.index1 = e.detail.value;
      this.newExpress.informman = this.colleagueList[this.index1];
    },

    //寄件时间发生改变时执行方法
    bindDateChange(event) {
      this.newExpress.mailTime = event.detail.value;
    },

    //显示时间选择器
    showChoise() {
      this.isChoiseMailTime = true;
    },

    //确认关闭时间选择器
    confirmChiose(e) {
      this.currentDate = e.detail;
      this.isChoiseMailTime = false;
    },

    //取消关闭时间选择器
    closeChiose(e) {
      this.currentDate = this.currentDate;
      this.isChoiseMailTime = false;
    },

    // 项目名称输入失去焦点时关闭项目名称提示
    closeHintOptions() {
      this.showHint = false;
    },

    //实时获取项目名称
    getProjectName() {
      var that = this;
      var projectNames = [];
      console.log(that.newExpress.projectname);
      if (
        that.newExpress.projectname &&
        that.newExpress.projectname.trim() != "" &&
        !that.isOis
      ) {
        var projectNames = that.projectList.filter((item) => {
          return item.indexOf(that.newExpress.projectname) > -1;
        });
        that.hintOptions = projectNames;
        that.showHint = true;
      } else {
        that.showHint = false;
      }
    },

    // 点击项目名称提示
    comfirmProjectName(e) {
      var that = this;

      setTimeout(() => {
        that.newExpress.projectname = e.currentTarget.dataset.value;
        that.showHint = false;
        that.isOis = true;

        setTimeout(() => {
          that.isOis = false;
        }, 1000);
      }, 50);
    },

    //项目输入模式转为输入
    switchModeToInput() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定需要将项目输入模式改为自主输入吗？",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          // var str = "newExpress.projectname";
          // this.setData({
          //   mode: "input",
          //   [str]: "",
          //   index: 0,
          // });

          this.mode = "input";
          this.newExpress.projectname = "";
          this.index = 0;
        })
        .catch(() => {
          // on cancel
        });
    },

    //项目输入模式转为选择
    switchModeToSelect() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定需要将项目输入模式改为项目选择吗？",
        confirmButtonColor: "#3c43e3",
      })
        .then(() => {
          this.mode = "select";
          this.newExpress.projectname = "";
        })
        .catch(() => {
          // on cancel
        });
    },

    //快递登记
    submitExpress(event) {
      var that = this;
      var re = /^[0-9]+.?[0-9]*/;
      if (
        this.newExpress.expressnum &&
        this.newExpress.contractnum &&
        this.newExpress.projectname &&
        this.newExpress.mailservice &&
        this.newExpress.sendername &&
        this.newExpress.mailTime &&
        this.newExpress.consigneeinfor &&
        this.newExpress.cost &&
        this.newExpress.informman &&
        this.fileList.length != 0 &&
        this.fileList[0].savePath
      ) {
        if (re.test(parseFloat(this.newExpress.cost))) {
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "快递登记中...",
            loadingType: "spinner",
          });
          let fd = new FormData();

          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          let info = {
            IsSystem: false,
            data: [
              {
                "@ClassName": "peacepay",
                "@MethodName": "SaveExpressRegistration",
              },
              {
                ExpressRegistration_Num: this.newExpress.expressnum,
                ExpressRegistration_ContractNum: this.newExpress.contractnum,
                ExpressRegistration_ProjectName: this.newExpress.projectname,
                ExpressRegistration_MailService: this.newExpress.mailservice,
                ExpressRegistration_SenderName: this.newExpress.sendername,
                ExpressRegistration_MailTime: this.newExpress.mailTime,
                ExpressRegistration_ConsigneeInfor: this.newExpress
                  .consigneeinfor,
                ExpressRegistration_Cost: this.newExpress.cost,
                ExpressRegistration_IsSignFor: "false",
                ExpressRegistration_OverTime: "10",
                ExpressRegistration_SendOffTime: "0",
                ExpressRegistration_InformMan: this.newExpress.informman,
                ExpressRegistration_StubUrl: this.fileList[0].savePath,
                ExpressRegistration_LogisticsInfor: "",
                ExpressRegistration_ExpressUnusual: "",
                ExpressRegistration_EnterpriseID: JSON.parse(
                  sessionStorage.getItem("userInfo")
                ).orgID,
                ExpressRegistration_EnterpriseName: JSON.parse(
                  sessionStorage.getItem("userInfo")
                ).EnterpriseName,
                ExpressRegistration_Founder: JSON.parse(
                  sessionStorage.getItem("userInfo")
                ).userName,
                ExpressRegistration_Text1: "",
                ExpressRegistration_Text2: "",
                ExpressRegistration_Text3: "",
                ExpressRegistration_Text4: "",
                ExpressRegistration_Text5: "",
              },
            ],
          };

          fd.append("inputJson", JSON.stringify(info));

          this.$axios
            .post(
              "https://beyondsky.docuvix.com/peacepay/api/UniversalAccessDB/GeneralOpreateData",
              fd,
              config
            )
            .then((res) => {
              console.log(res);
              if (res.data.errorCode == "1") {
                this.newExpress = {
                  expressnum: "",
                  contractnum: "",
                  projectname: "无项目",
                  mailservice: "",
                  sendername: "",
                  mailTime: "",
                  consigneeinfor: "",
                  cost: "",
                  informman: "无通知人",
                  UserID: "",
                };

                this.fileList = [];
                Toast("登记成功");
              } else {
                Toast(res.data.errorText);
              }
            })
            .catch((err) => {
              Toast(err);
            });
        } else {
          Toast("快递费用请输入数字");
        }
      } else {
        Toast("请将信息输入完整");
      }
    },

    // 清空表格
    clear() {
      this.newExpress = {
        expressnum: "",
        contractnum: "",
        projectname: "无项目",
        mailservice: "",
        sendername: "",
        mailTime: "",
        consigneeinfor: "",
        cost: "",
        informman: "无通知人",
      };
      this.index = 0;
      this.index1 = 0;
      this.fileList = [];

      this.deleteTemporaryStorage();
    },
  },
  created() {
    this.getTemporaryStorage();
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } else {
      this.userInfo = {
        EnterpriseName: "道可维斯信息技术有限公司",
        account: "0004@经办人",
        email: "wenqiang.li@docuvix.com",
        id: "50b1bd2f-65f9-11eb-9a5c-00163e1697a5",
        orgID: "50b1bd0b-65f9-11eb-9a5c-00163e1697a5",
        password: "e10adc3949ba59abbe56e057f20f883e",
        phone: "18218525166",
        role: "经办人",
        roleID: "8b5352fa-3a82-4aa3-a2dd-6a505b306068",
        userName: "刘文强",
      };
    }

    this.getColleagueList();
  },
  destroyed() {
    this.setTemporaryStorage();
  },
};
</script>

<style lang="less">
/* view/register/register.wxss */
.expressRegistration {
  .placeholderinput {
    color: #cccccc;
    font-size: 32rpx;
    line-height: 30px;
  }

  .header {
    width: 100vw;
    height: 44px;
    line-height: 44px;
    background-color: #3c43e3;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
  }

  .expressRegistration {
    padding-bottom: 60px;
  }

  .register {
    padding: 15px;
    padding-bottom: 55px;
    /* background-color: #eff7fa; */
    background-color: #f6f6f6;
  }

  .wrap {
    width: 100%;
    padding: 15px;
    padding-left: 20px;
    padding-bottom: 5px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    margin-bottom: 15px;
  }

  .register .item .van-picker__cancel {
    padding: 0;
  }

  .register .item {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
  }

  .register .item.stub {
    border: 0;
    display: block;
    position: relative;

    .imageWrap {
      position: relative;
      width: 80px;
      height: 80px;

      .deleteImage {
        position: absolute;
        top: -12px;
        right: -12px;
        z-index: 2000;
      }
    }

    .uploadImage {
      width: 80px;
      height: 80px;
      background-color: #f5f5f5;
      border-radius: 5px;
      position: relative;
      display: inline-block;
      margin-right: 10px;

      img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        opacity: 0;
      }
    }
  }

  .register .item.recipients {
    border: 0;
    display: block;
    position: relative;
  }

  .register .item.article {
    border: 0;
    display: block;
    position: relative;
  }

  .register .item input {
    width: 100%;
    height: 100%;
    text-align: right;
  }

  .register .item .title {
    line-height: 24px;
    font-size: 32rpx;
    color: #666666;
    margin-bottom: 5px;
    margin-right: 10px;
    position: relative;
  }

  .register .item .title::after {
    content: "*";
    color: red;
    position: absolute;
    right: -10px;
    top: 0;
  }

  .register .item.stub .title {
    display: inline-block;
  }

  .register .item.stub .title::before {
    content: "";
    width: 5px;
    height: 14px;
    background-color: #3c43e3;
    position: absolute;
    top: 5px;
    left: -10px;
  }

  .register .item.recipients .title {
    display: inline-block;
  }

  .register .item.recipients .title::before {
    content: "";
    width: 5px;
    height: 14px;
    background-color: #3c43e3;
    position: absolute;
    top: 5px;
    left: -10px;
  }

  .register .item.article .title {
    display: inline-block;
  }

  .register .item.article .title::before {
    content: "";
    width: 5px;
    height: 14px;
    background-color: #3c43e3;
    position: absolute;
    top: 5px;
    left: -10px;
  }

  .register .item .value {
    flex: 1;
    text-align: right;
  }

  .register .item.expressNum .value img {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    margin-left: 10px;
  }

  .register .item.expressNum .value input {
    vertical-align: middle;
  }

  .register .item.project .value {
    position: relative;
  }

  .register .item.project .value.active {
    color: #cccccc;
  }

  .register .item.project .value .hintOptions {
    position: absolute;
    top: 31px;
    right: 0;
    width: 170px;
    max-height: 320px;
    overflow-y: auto;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    z-index: 999;
  }

  .register .item.project .value .hintOptions .option {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    font-size: 14px;
    /* border-bottom: 1px solid #BEBEBE; */
    text-align: left;
    box-sizing: border-box;
    background-color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .register .item.project .value .Mode {
    background-color: #3c43e3;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    color: #ffffff;
    font-size: 28rpx;
    padding: 0 7px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    border: none;
    outline: none;
    vertical-align: top;
  }

  .register .item.project .value .projectName-input {
    width: 170px;
    vertical-align: top;
  }

  .register .item.stub .value {
    text-align: left;
  }

  .register .item.recipients .value {
    text-align: left;
  }

  .register .item.article .value {
    text-align: left;
  }

  .register .item .value input {
    width: 190px;
    height: 24px;
    line-height: 24px;
    font-size: 32rpx;
    color: #333333;
    text-align: right;
    display: inline-block;
  }

  .register .item .value textarea {
    width: 100%;
    height: 80px;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333333;
    padding: 8px;
    box-sizing: border-box;
    font-size: 32rpx;
  }

  .register .item .section {
    flex: 1;
    text-align: right;
  }

  .register .expressNum .value {
    text-align: right;
  }

  .register .expressNum .value .expressNum-input {
    width: 150px;
    vertical-align: middle;
  }

  .register .expressNum image {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    vertical-align: middle;
  }

  .register .item.mailTime .mailTime-value {
    width: 190px;
    height: 24px;
    line-height: 24px;
    font-size: 32rpx;
    color: #cccccc;
    text-align: right;
    display: inline-block;
  }

  .register .item.mailTime .mailTime-value.active {
    color: #333333;
  }

  .register .buttons {
    width: 100%;
    text-align: center;
  }

  .register .van-button--default {
    width: calc(40% - 10px);
    margin-right: 20px;
  }

  .register .van-button--info {
    width: calc(60% - 10px);
  }

  .register .project .value picker {
    width: 170px;
    display: inline-block;
    vertical-align: top;
  }

  .register .project .value .picker {
    width: 100%;
    line-height: 24px;
    font-size: 32rpx;
    color: #cccccc;
    border-radius: 5px;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: normal;
  }

  .register .project .value .picker.active {
    color: #333333;
  }

  /* .register .project .value .picker input {
  color: #cccccc;
}

.register .project .value .picker.active input {
  color: #333333;
} */

  .register .item .value .mailTime-input {
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 32rpx;
    color: #999999;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .formanSelect {
    /* text-align: center; */
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }

  .formanSelect .buttons {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .formanSelect .buttons button#formanSelectCancel {
    float: left;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 5px 13px;
    border-radius: 5px;
  }

  .formanSelect .buttons button#formanSelectConfirm {
    float: right;
    color: #ffffff;
    background-color: #3c43e3;
    border: 1px solid #3c43e3;
    padding: 5px 13px;
    border-radius: 5px;
  }

  /* .select button {
  width: calc(50% - 30px);
}

.select button.van-button--default {
  margin-right: 20px;
} */

  .formanSelect .options {
    width: 100%;
    height: 300px;
    overflow-y: auto;
  }

  .formanSelect .options .option {
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .formanSelect .options .option .van-checkbox__label {
    flex: 1;
    text-align: left;
  }
}
</style>