var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"searchResult"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"close"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":_vm.goback}})],1),_c('span',[_vm._v("搜索结果")])]),_c('div',{staticClass:"express"},[(_vm.resultExpress.length > 0)?_vm._l((_vm.resultExpress),function(item,index){return _c('div',{key:index,staticClass:"expressItem",on:{"click":function($event){return _vm.toExpressDetail(index)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"tag"},[(
                item.ExpressRegistration_ExpressUnusual &&
                item.ExpressRegistration_ExpressUnusual != ''
              )?_c('van-tag',{attrs:{"type":"danger"}},[_vm._v(_vm._s(item.ExpressRegistration_ExpressUnusual))]):(item.ExpressRegistration_IsSignFor == 'true')?_c('van-tag',{attrs:{"type":"success"}},[_vm._v("已签收")]):(
                item.ExpressRegistration_IsSignFor == 'false' &&
                item.ExpressRegistration_OverTime -
                  item.ExpressRegistration_SendOffTime >=
                  0
              )?_c('van-tag',{attrs:{"type":"warning"}},[_vm._v("未签收")]):(
                item.ExpressRegistration_IsSignFor == 'false' &&
                item.ExpressRegistration_OverTime -
                  item.ExpressRegistration_SendOffTime <
                  0
              )?_c('van-tag',{attrs:{"type":"danger"}},[_vm._v("已超时")]):_vm._e()],1),_c('div',{staticClass:"expressNumber"},[_vm._v(" "+_vm._s(item.ExpressRegistration_Num)+" ")]),_c('div',{staticClass:"cost"},[_vm._v(_vm._s(item.ExpressRegistration_Cost)+"元")])]),_c('div',{staticClass:"middle"},[_vm._v(_vm._s(item.ExpressRegistration_ProjectName))]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"goodsText"},[_vm._v("物品：")]),_c('div',{staticClass:"goods"},[_vm._v(_vm._s(item.ExpressRegistration_MailService))])])])}):_c('Hint2',{attrs:{"text":"暂无符合条件的快递"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }